import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { AreasService } from '../areas.service';
import { Area } from 'src/app/entities/units/area.entity';
import { CleaningService, CleaningQR } from '../../cleaning/cleaning.service';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss']
})
export class AreaFormComponent implements OnInit {

  form: FormGroup;
  area: Area = new Area();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;

  cleaningQR: CleaningQR = {
    type: 'area',
    hotelCode: null,
  };

  codeQRencoded = '';
  codeSRC = '';

  constructor(
    private formBuilder: FormBuilder,
    public service: AreasService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public cleaningService: CleaningService) {
    }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.hotelInfo = this.navService.hotelInfo;
      this.cleaningQR.hotelCode = this.hotelInfo.hotelCode;

      if (params.areaId) {
        this.id = params.areaId;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(area => {
      this.loading = false;
      this.errorMessage = null;

      this.area = area;

      this.form.patchValue({
        ['name']: area.name,
      });
      this.cleaningQR.areaId = this.id;
      this.calcCode();

    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.area = new Area(this.form.value);
    this.area.unit = new Unit({ hotelCode: this.hotelInfo.hotelCode});
    this.service.create(this.area).then(area => {
      this.successNew(area);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create area component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else if (err.statusCode === 409) {
        this.errorMessage = 'Este area já está registrado nessa unidade. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  update() {
    this.loading = true;
    const area = new Area({
      name: this.form.value.name,
      unit: {hotelCode: this.hotelInfo.hotelCode},
    });
    this.service.update(area, this.id).then(_area => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update area component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else if (err.statusCode === 409) {
        this.errorMessage = 'Este area já está registrado nessa unidade. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  calcCode() {
    if (this.cleaningQR.type === 'area') {
      delete this.cleaningQR.roomId;
    } else if (this.cleaningQR.type === 'room') {
      delete this.cleaningQR.areaId;
    }
    this.loading = true;
    this.cleaningService.generateQR(this.cleaningQR).then((resp) => {
      this.codeQRencoded = resp.QR;
      setTimeout(() => {
        this.codeSRC = document.querySelector('#QR img').getAttribute('src');
      }, 100);
      this.loading = false;
    });
  }

  printQR() {
    const img = document.querySelector('#QR img');
    img.setAttribute('style', 'display: initial;');

    const popup = window.open();
    popup.document.write(`<div style='display: table-cell;
                                      vertical-align: middle;
                                      height: 100vh;
                                      text-align: center;
                                      width: 100vw;
                                      color: rgb(241, 128, 65);'>`);
    popup.document.write(img.outerHTML);
    // tslint:disable-next-line: max-line-length
    popup.document.write(`<p><img src="https://i.imgur.com/F0zipA9.png" height="20" style="position: relative;top: 5px;"/> <b>Uliving</b> - `);
    popup.document.write(
      this.cleaningQR.type === 'room' ?
        ('Quarto ' + this.cleaningQR.roomId) :
        ('Área: ' + this.area.name)
    );
    popup.document.write(`</p></div>`);
    popup.focus();
    setTimeout(() => {
      popup.print();
    }, 500);
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  successNew(area: Area) {
    Swal.fire({
      title: 'Area ' + this.area.name + ' cadastrada',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para area',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(area.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Area editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir esta área?',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
      if (resp.value) {
        this.delete(id);
      }
    });
  }

  delete(id: number) {
    this.service
      .delete(id)
      .then(del => {
        Swal.fire({
          type: 'success',
          text: 'Área' + id + ' deletado com sucesso.',
          titleText: 'Excluído.',
        }).then(resp => {
          this.goToList();
        });
      })
      .catch(err => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'areas']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'areas', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'areas', 'create']);
  }

}
