import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ErrorsEnum, ErrorsTranslated } from 'src/app/shared/errorMessagesTranslation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }

  resetPassword() {
    this.authService.resetPassword(this.resetPasswordForm.value.email)
    .then(() => {
      Swal.fire({
        type: 'success',
        text: 'Senha de recuperação enviada com sucesso!',
        title: 'Email enviado',
        showConfirmButton: true,
        onClose: () => this.router.navigate(['/login']),
      });
    },
    (err) => {
      if (err.error === ErrorsEnum.EMAIL_NOT_FOUND) {
        Swal.fire({
          type: 'error',
          text: ErrorsTranslated[ErrorsEnum.EMAIL_NOT_FOUND],
          title: 'Ops',
          showConfirmButton: true,
          onClose: () => this.resetPasswordForm.reset(),
        });
      } else {
        console.error(err);
        Swal.fire({
          type: 'error',
          text: 'Ocorreu um erro...',
          title: 'Ops',
          showConfirmButton: true,
          onClose: () => this.resetPasswordForm.reset(),
        });
      }
    })
  }

  showError() {
    return (this.resetPasswordForm.get('email').invalid && this.resetPasswordForm.get('email').touched);
  }

}
