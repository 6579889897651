import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './entities/residents/user.entity';
import { APIService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { NavService } from './shared/service/nav.service';
import { Unit } from './entities/units/unit.entity';


interface Session {
  token: string;
  user: any;
  id: number;
  createdDate: string;
}
interface IUpdatePassword {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // user: User = null;
  user: User;
  user$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  constructor(
    private http: HttpClient,
    private API: APIService,
    private toaster: ToastrService,
    public router: Router,
    public navSrvc: NavService,
  ) {}

  setPassword(password: string) {
    return this.API.post<{ password: string }>(
      '/users/setPassword',
      {
        password,
      },
      { tokenRequired: true }
    );
  }

 updatePassword({
   currentPassword,
   newPassword,
   newPasswordConfirmation,
 }: IUpdatePassword) {
    return this.API.patch<IUpdatePassword>(
      '/users/updatePassword',
      {
        currentPassword,
        newPassword,
        newPasswordConfirmation,
      },
      { tokenRequired: true }
    );
  }

  resetPassword(email: string) {
    return this.API.post<{ email: string }>(
      '/auth/recoverPassword',
      {
        email,
      },
      { tokenRequired: false }
    );
  }


  async login(email: string, password: string) {
    await this.API.post<
      { email: string; password: string },
      Session
    >('/auth/loginAdmin', {
      email,
      password,
    }).then(
      session => {
        if (session.token) {
      this.API.setAccessToken(session.token);
      const user = session.user;
      this.user = new User(user);
      this.user$.next(new User(user));
      if (user && user.employee && user.employee.unit) {
        this.navSrvc.currentUnit$.next(user.employee.unit);

        window.localStorage.setItem('currentUnit', user.employee.unit.code);
        window.localStorage.setItem('currentHotel', user.employee.unit.hotelCode.toString());

      }
      this.API.setLoggedUser(this.user);
      this.toaster.success(`Bem vindo(a), ${user.name}`);
      return session;
    }
      },
      err => {
        throw err;
      }
    );
  }

  async logout() {
    window.localStorage.setItem('currentUnit', null);
    window.localStorage.setItem('currentHotel', null);
    console.info('Loggin out...');
    if (!this.API.hasSession()) {
      throw new Error('Trying to logout with null session');
    }
    await this.API.patch('/auth/logout', {}, { tokenRequired: true, hideLoading: true });
    await this.API.clearSession();
    return await this.router.navigate(['/login']);
  }

  getLoggedProfile() {
    if (this.API.hasSession()) {
      return this.API.getLoggedUser();
    }
  }
}
