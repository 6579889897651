import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { Unit } from '../entities/units/unit.entity';
import { Schedule } from '../entities/spaces/schedule.entity';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(
    public API: APIService
  ) { }

  async getAll() {
    try {
      var units = await this.API.get<Unit[]>('/units', {}, {tokenRequired: true, hideLoading: true});
      return units.map(u => new Unit(u));
    } catch (error) {
      console.error("get all error ", error);
    }
  }

  create(unit: Unit) {
    unit = this.clearNulls(unit);
    return this.API.post<Unit, Unit>('/units', unit, {tokenRequired: true});
  }

  read(hotelCode: number) {
    return this.API.get<Unit>(`/units/${hotelCode}`, {}, {tokenRequired: true});
  }

  update(unit: Unit, hotelCode: number) {
    return this.API.patch<Unit, Unit>(`/units/${hotelCode}`, unit, {tokenRequired: true});
  }

  clearNulls(unit: Unit) {
    for (const key in unit) {
      if (unit[key] === null || unit[key] === undefined) {
        delete unit[key];
      }
    }
    return unit;
  }

  updateDocument(hotelCode: number, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.API.uploadFile<Unit>(`/units/${hotelCode}/updateDocument`, formData);
  }
}
