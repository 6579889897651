import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { showDefaultErrorAlert } from '../../api.service';
import { OccurrenceCategory, OccurrenceCategorySortOptions } from '../occurrence-categories.entity';
import { OccurrenceCategoryService } from '../occurrence-categories.service';
import { occurrenceTypeTranslator } from 'src/app/occorrences/occurrence.service';

@Component({
  selector: 'app-list-occurrencecategories',
  templateUrl: './list-occurrence-category.component.html',
  styleUrls: ['./list-occurrence-category.component.scss'],
})
export class ListOccurrenceCategoriesComponent implements OnInit {
  occurrenceCategories: OccurrenceCategory[] = [];
  searchTerm = '';
  loading = true;
  sortBy: OccurrenceCategorySortOptions;

  sortByOptions = OccurrenceCategorySortOptions;

  occurrenceTypeTranslator = occurrenceTypeTranslator;

  constructor(public service: OccurrenceCategoryService, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.getAll();
    });
  }

  loadIssueCategories() {
    let sortOption: string;

    switch (this.sortBy) {
      case OccurrenceCategorySortOptions.CATEGORY:
        // sortOption = 'type,ASC';
        this.sortByCategoryName('ASC');
        break;
      case OccurrenceCategorySortOptions.CATEGORYINVERSE:
        // sortOption = 'type,DESC';
        this.sortByCategoryName('DESC');
        break;
      case OccurrenceCategorySortOptions.SUBCAT:
        sortOption = 'name,ASC';
        break;
      case OccurrenceCategorySortOptions.SUBCATINVERSE:
        sortOption = 'name,DESC';
        break;
      default:
        sortOption = '';
        break;
    }
    if (this.sortBy === OccurrenceCategorySortOptions.SUBCAT || this.sortBy === OccurrenceCategorySortOptions.SUBCATINVERSE) {
      this.getAll(sortOption);
    }
  }

  sortByCategoryName(order: 'ASC' | 'DESC') {
    this.occurrenceCategories.sort((leftOccurrenceCategory: OccurrenceCategory, rightOccurrenceCategory: OccurrenceCategory) => {
      if (occurrenceTypeTranslator(leftOccurrenceCategory.type) > occurrenceTypeTranslator(rightOccurrenceCategory.type)) {
        return order === 'DESC' ? -1 : 1;
      }
      if (occurrenceTypeTranslator(rightOccurrenceCategory.type) > occurrenceTypeTranslator(leftOccurrenceCategory.type)) {
        return order === 'DESC' ? 1 : -1;
      }
      return 0;
    });
  }

  async getAll(sort?: string) {
    this.loading = true;
    this.occurrenceCategories = await this.service.getAll(sort).catch((e) => {
      showDefaultErrorAlert(e);
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }
}
