import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export function dateRangeValidator(startDateField: string, endDateField: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const startDateControl = formGroup.get(startDateField);
    const endDateControl = formGroup.get(endDateField);

    if (!startDateControl.value && !endDateControl.value) {
      return null;
    }

      const startDate = new Date(startDateControl.value);
      const endDate = new Date(endDateControl.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);


      // Obter a diferença de tempo em milissegundos entre o horário local e o UTC
      const millisecondsDiff = today.getTimezoneOffset() * 60000;
      startDate.setTime(startDate.getTime() + millisecondsDiff);
      endDate.setTime(endDate.getTime() + millisecondsDiff);

      if (startDateControl.value && startDate < today) {
        startDateControl.setErrors({ [`${startDateField}Invalid`]: true });
      } else {
        startDateControl.setErrors(null);
      }

      if (endDateControl.value && endDate < startDate) {
        endDateControl.setErrors({ [`${endDateField}Invalid`]: true });
      } else {
        endDateControl.setErrors(null);
      }


    return null;
  };
}
