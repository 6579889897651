import { Component, OnInit } from '@angular/core';
import { CleaningType, Frequency } from '../../entities/cleaning/cleaning-type.entity';
import { CleaningService } from '../cleaning.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cleaning-form',
  templateUrl: './cleaning-form.component.html',
  styleUrls: ['./cleaning-form.component.scss']
})
export class CleaningFormComponent implements OnInit {

  form: FormGroup;
  type: CleaningType = new CleaningType();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;
  constructor(
    private formBuilder: FormBuilder,
    public service: CleaningService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService) {
    }

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;

    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      price: [null, [Validators.required, Validators.min(0)]],
      duration: [null, [Validators.required]],
      description: [null],
      iconUrl: [null],
      frequency: [Frequency.SINGLE],
      packQuantity: [1],
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.readType(this.id).then(type => {
      this.loading = false;
      this.errorMessage = null;

      this.type = type;


      this.form.patchValue({
        ['name']: type.name,
        ['price']: type.price,
        ['duration']: type.duration,
        ['description']: type.description,
        ['iconUrl']: type.iconUrl,
        ['frequency']: type.frequency,
        ['packQuantity']: type.packQuantity,
      });
    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.type = new CleaningType(this.form.value);
    this.type.packQuantity = null;
    this.type.unit = new Unit({ hotelCode: this.hotelInfo.hotelCode});

    this.service.createType(this.type).then(type => {
      this.successNew(type);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create type component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  update() {
    this.loading = true;

    const type = new CleaningType({
      name: this.form.value.name,
      price: this.form.value.price,
      duration: this.form.value.duration,
      description: this.form.value.description,
      iconUrl: this.form.value.iconUrl,
      frequency: this.form.value.frequency,
      packQuantity: null,
    });
    this.service.updateType(type, this.id).then(_type => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update type component', err);
      this.loading = false;
      if (err.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
        if (err.message && err.message.length > 0) {
          for (const e of err.message) {
            this.form.get(e.property).setErrors({
              error: true
            });
          }
        }
       } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    });
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  successNew(type: CleaningType) {
    Swal.fire({
      title: 'Tipo ' + this.type.name + ' criado',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para tipo',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(type.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Limpeza editada!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning',  'cleaning-types']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning', 'cleaning-types', id]);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'cleaning', 'cleaning-types', 'create']);
  }

}
