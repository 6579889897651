import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APIService } from '../../api.service';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    constructor(private API: APIService, private router: Router) { }
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.API.hasSession()) {
            Swal.fire({
                type: 'error',
                titleText: 'Área restrita',
                text: 'Você precisa estar logado como administrador para visualizar essa página'
            });
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}
