import { Injectable } from '@angular/core';
import { APIService, APIPaginationdResponse, APIRequestOptions } from '../api.service';
import { Guest, GuestQuantitativeReport } from '../entities/residents/guest.entity';
import { GuestRelationInterface } from './totvs.service';
import { ReservationStatus } from '../entities/residents/totvs.interfaces';
import { UserStatus, User } from '../entities/residents/user.entity';

@Injectable({
  providedIn: 'root',
})
export class ResidentsService {
  constructor(public API: APIService) {}

  async getAllGuests(
    hotelCode: number,
    page?: number,
    limit?: number,
    reservationStatus?: ReservationStatus | ReservationStatus[],
    userStatus?: UserStatus | 'PENDING',
  ): Promise<APIPaginationdResponse<Guest>> {
    const params = {
      page: page || 1,
      limit: limit || 1000000,
    };

    if (reservationStatus) {
      Object.assign(params, { reservationStatus });
    }

    if (userStatus) {
      Object.assign(params, { userStatus });
    }

    if (!hotelCode) {
      return { data: [], totalPages: 0 };
    }
    const response = await this.API.get<APIPaginationdResponse<Guest>>('/guests/' + hotelCode + '/all', params, { tokenRequired: true });
    const residents = response.data.map((g) => new Guest(g));
    return { ...response, data: residents };
  }

  async search(hotelCode: number, query?: string) {
    const props = ['GuestCode', 'RoomNumber', 'Name', 'DocumentNumber', 'HIGSReservationNumber', 'PMSReservationNumber', 'SourceReservationNumber'];
    let searchString = '';

    if (query && query.length > 0) {
      for (const prop of props) {
        searchString = searchString.concat(`or=${prop}||cont||${query}&`);
      }
    }

    const residents = await this.API.get<Guest[]>(
      '/guests?' + searchString,
      {
        sort: 'GuestCode,ASC',
        filter: 'HotelCode||eq||' + hotelCode,
      },
      { tokenRequired: true },
    );
    return residents && residents.length ? residents.map((g) => new Guest(g)) : [];
  }

  async searchGuests(hotelCode: number, query: string) {
    const residents = await this.API.get<Guest[]>(
      '/guests/search',
      {
        HotelCode: hotelCode,
        q: query,
      },
      { tokenRequired: true },
    );
    return residents && residents.length ? residents.map((g) => new Guest(g)) : [];
  }

  create(guest: Guest) {
    return this.API.post<Guest, Guest>('/guests', guest, { tokenRequired: true });
  }

  read(guestCode: number) {
    return this.API.get<Guest>(`/guests/${guestCode}`, {}, { tokenRequired: true });
  }

  update(guest: Guest, guestCode: number) {
    return this.API.patch<Guest, Guest>(`/guests/${guestCode}`, guest, { tokenRequired: true });
  }

  setAvatar(userId: number, avatar: any) {
    const formData = new FormData();
    formData.append('file', avatar);
    return this.API.uploadFile<User>(`/users/${userId}/setAvatar`, formData);
  }

  async syncGuests(guestRelations: GuestRelationInterface[], options?: APIRequestOptions) {
    const relationsWithoutAppGuest = guestRelations.map((relation) => ({ ...relation, appGuest: undefined }));
    return this.API.post<any>('/guests/syncGuests', relationsWithoutAppGuest, { ...options, tokenRequired: true });
  }

  uploadSheet(file: any, hotelCode: number) {
    const formData = new FormData();
    formData.append('file', file);
    return this.API.uploadFile(`/guests/${hotelCode}/importXLSX`, formData, { tokenRequired: true });
  }

  delete(id: number) {
    return this.API.delete<Guest>(`/guests/${id}`, {}, { tokenRequired: true });
  }

  async checkout(guestCode: number) {
    return this.API.post<Guest | {}>(`/guests/${guestCode}/checkout`, {}, { tokenRequired: true });
  }

  async getReports(hotelCode: number): Promise<GuestQuantitativeReport> {
    return this.API.get<GuestQuantitativeReport>('/guests/reports', { hotelCode: String(hotelCode) }, { tokenRequired: true, hideLoading: true });
  }
}
