import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Employee } from '../../entities/employees/employee.entity';
import { EmployeesService } from '../employees.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserRole } from '../../entities/residents/user.entity';
import Swal from 'sweetalert2';
import { NavService } from '../../shared/service/nav.service';
import { Unit } from '../../entities/units/unit.entity';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss'],
})
export class EmployeeFormComponent implements OnInit {
  form: FormGroup;
  employee: Employee = new Employee();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  role: UserRole;
  id: number = undefined;
  roles = [
    { key: UserRole.Porter, label: 'Student Relations' },
    { key: UserRole.Manager, label: 'Community Manager' },
    { key: UserRole.Marketing, label: 'Marketing' },
    { key: UserRole.Cleaning, label: 'Limpeza' },
    { key: UserRole.Maintenance, label: 'Manutenção' },
    { key: UserRole.Commercial, label: 'Comercial' },
    { key: UserRole.Doorman, label: 'Doorman' },
    { key: UserRole.Facilities, label: 'Facilities' },
  ];
  isMatriz: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public service: EmployeesService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    private toastSrvc: ToastrService,
  ) {
    this.updateEnum();
  }

  updateEnum() {
    this.roles.forEach( role => {
      if (role.label === 'Administrador') {
        this.roles.pop();
      }
    });

    if (this.navService.hotelInfo.code === 'Matriz') {
      this.roles.push({ key: UserRole.Admin, label: 'Administrador' },);
    }

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.role = params.role;

      if (params.employeeId) {
        this.id = params.employeeId;
        this.initCreateForm(false);
        this.initUpdateForm();
      } else {
        this.initCreateForm(true);
      }
    });

    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;
    });
  }

  initCreateForm(isNew) {
    const passwordValidators = isNew ? [Validators.required, Validators.minLength(8)] : [Validators.minLength(8)];

    this.form = this.formBuilder.group(
      {
        user: new FormGroup({
          email: new FormControl('@uliving.com.br', [Validators.required, Validators.email]),
          password: new FormControl(null, passwordValidators),
          confirmPassword: new FormControl(null, passwordValidators),
          role: new FormControl(this.role, Validators.required),
        }),
        firstName: [null, [Validators.required, Validators.minLength(2)]],
        lastName: [null, [Validators.required, Validators.minLength(2)]],
        phone: [null, [Validators.minLength(8)]],
        cellphone: [null, [Validators.minLength(8)]],
        workingHours: [null],
        isUleader: [false, []],
        roleDescription: [null, [Validators.required, Validators.minLength(2)]],
      },
      { validators: this.checkPasswords },
    );
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(
      emp => {
        this.loading = false;
        this.errorMessage = null;

        this.employee = emp;

        this.form.patchValue({
          ['user']: {
            email: emp.user.email,
            role: emp.user.role,
          },
          ['firstName']: emp.firstName,
          ['lastName']: emp.lastName,
          ['phone']: emp.phone,
          ['cellphone']: emp.cellphone,
          ['workingHours']: emp.workingHours,
          ['isUleader']: emp.isUleader,
          ['roleDescription']: emp.roleDescription,
        });
      },
      err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  update() {
    this.loading = true;
    const employee = new Employee({
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      cellphone: this.form.value.cellphone,
      phone: this.form.value.phone,
      workingHours: this.form.value.workingHours,
      isUleader: this.form.value.isUleader,
      roleDescription: this.form.value.roleDescription,
      unit: { hotelCode: this.hotelInfo.hotelCode },
      user: {
        password: this.form.value.user.password,
        name: this.form.value.firstName,
        email: this.form.value.user.email,
        role: this.form.value.user.role,
        id: this.employee.user.id,
      },
    });
    this.service.update(employee, this.id).then(
      emp => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in update employee component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  create() {
    this.loading = true;
    const employee = new Employee({
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      cellphone: this.form.value.cellphone,
      phone: this.form.value.phone,
      workingHours: this.form.value.workingHours,
      isUleader: this.form.value.isUleader,
      roleDescription: this.form.value.roleDescription,
      unit: { hotelCode: this.hotelInfo.hotelCode },
      user: {
        name: this.form.value.firstName,
        email: this.form.value.user.email,
        password: this.form.value.user.password,
        role: this.form.value.user.role,
      },
    });
    this.service.create(employee).then(
      emp => {
        this.successNew(emp);
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in create employee component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  async createAdmin() {
    const user = new User({
      name: `${this.form.value.firstName} ${this.form.value.lastName}`,
      email: this.form.value.user.email,
      password: this.form.value.user.password,
      role: this.form.getRawValue().user.role,
    });


    try {
      this.loading = true;
      const createdUser = await this.service.createAdmin(user);
      if (createdUser) {
        this.toastSrvc.success('Administrador cadastrado');
      }
    } catch (e) {
      console.error('Error when creating admin', e);
      if (e.statusCode === 400) {
        this.errorMessage = 'Ops! Verifique os campos abaixo';
      } else if (e.statusCode === 409) {
        this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
      } else {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      }
    } finally {
      this.loading = false;
    }
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const password = group.get('user.password');
    const confirmPassword = group.get('user.confirmPassword');
    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ notSame: true });
    } else {
      confirmPassword.setErrors(null);
    }
    return password.value === confirmPassword.value ? null : { notSame: true };
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) || (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0);
  }

  successNew(emp: Employee) {
    return Swal.fire({
      title: 'Funcionário criado!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para funcionário',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => {
        this.goToNew();
      },
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(emp.id);
      } else {
        this.goToNew();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Funcionário editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'employees', this.role || 'All']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'employees', this.role || 'All', id]);
  }
  goToNew() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'employees', this.role || 'All', 'create']);
  }
}
