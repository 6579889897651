import { Component, OnInit } from '@angular/core';
import { Guest, GuestQuantitativeReport } from 'src/app/entities/residents/guest.entity';
import { StatementService } from '../statement.service';
import { ActivatedRoute } from '@angular/router';
import { Unit } from '../../entities/units/unit.entity';
import { NavService } from '../../shared/service/nav.service';
import { UserStatus } from '../../entities/residents/user.entity';
import { Statement, StatementStatus, translateStatementStatus } from 'src/app/entities/statement/statement.entity';
import { ItemKind, StatementItem } from 'src/app/entities/statement/statement-item.entity';

@Component({
  selector: 'app-list-statement',
  templateUrl: './list-statement.component.html',
  styleUrls: ['./list-statement.component.scss'],
})
export class ListStatementsComponent implements OnInit {
  statements: Statement[] = [];
  originalListOfResidents: Guest[];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;
  files: any = [];
  loadingReport: boolean;
  months = [];
  ItemKind = ItemKind;
  translateStatementStatus = translateStatementStatus;
  periodValue = '';

  pagination = {
    page: 1,
    limit: 25,
    pages: 5,
    collectionSize: 100,
  };

  constructor(public statementSrvc: StatementService, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    const month_names_short = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    for (let x = 1; x <= 12; x++) {
      this.months.push({
        value: x,
        label: month_names_short[x - 1],
      });
    }

    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      if (this.hotelInfo) {
        this.getAll();
        //this.getReports();
      }
    });
  }

  onKey(event: any) {
    this.periodValue = event.target.value;
  }

  async getAll() {
    this.searchTerm = '';
    this.loading = true;
    const resp = await this.statementSrvc.list(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit).catch((e) => {
      alert('Erro ao buscar os extratos');
      this.loading = false;
      console.error(e);
      return { data: [], totalPages: 0 };
    });
    this.statements = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages * this.pagination.limit;
    this.loading = false;
  }

  async search() {
    if (!this.searchTerm && !this.periodValue) {
      return this.getAll();
    }

    this.loading = true;
    const resp = await this.statementSrvc.list(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit, this.periodValue, this.searchTerm).catch((e) => {
      alert('Erro ao buscar moradores');
      this.loading = false;
      console.error(e);
      return { data: [], totalPages: 0 };
    });
    this.statements = resp.data;
    this.pagination.pages = resp.totalPages;
    this.pagination.collectionSize = resp.totalPages & this.pagination.limit;
    this.pagination.collectionSize = 0;
    this.loading = false;
  }

  getItem(itemKind: ItemKind, items: StatementItem[]) {
    const item = items.find((item) => item.itemKind === itemKind);
    return item ? Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.value * item.amount) : '-';
  }

  getTotalStatement(items: StatementItem[]) {
    const total = items.reduce((acc, item) => {
      return acc + item.value * item.amount;
    }, 0);

    return Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total);
  }

  getStatusColor(status: StatementStatus) {
    return status === StatementStatus.PAID ? 'success' : 'warning';
  }
}
