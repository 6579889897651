import { Component, OnInit } from '@angular/core';
import { Space, SpaceCategory } from '../../entities/spaces/space.entity';
import { SpacesService } from '../spaces.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-spaces-form',
  templateUrl: './spaces-form.component.html',
  styleUrls: ['./spaces-form.component.scss'],
})
export class SpacesFormComponent implements OnInit {
  form: FormGroup;
  space: Space = new Space();
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;

  categoryOptions: { name: string; value: SpaceCategory }[] = [
    { name: 'Churrasco', value: SpaceCategory.BARBECUE },
    { name: 'Espaço de estudo', value: SpaceCategory.STUDY },
    { name: 'Espaço para eventos', value: SpaceCategory.EVENT },
  ];

  constructor(private formBuilder: FormBuilder, public service: SpacesService, public router: Router, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.initCreateForm();
    /** @TODO if is update, initUpdateForm  */

    this.route.params.subscribe((params) => {
      if (params.spaceId) {
        this.id = params.spaceId;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    // this.form = this.formBuilder.group(
    //   {
    //     name: [null, [Validators.required, Validators.minLength(2)]],
    //     minGuests: [1, [Validators.min(1), Validators.max(500)]],
    //     maxGuests: [null, [Validators.min(1), Validators.max(500)]],
    //     eventRequired: [false],
    //     fullDay: [false],
    //     step: [null, [Validators.min(10), Validators.max(1440)]],
    //     minInterval: [null, [Validators.min(10), Validators.max(1440)]],
    //     maxInterval: [null, [Validators.min(10), Validators.max(1440)]],
    //     minAntecedence: [0, [Validators.min(0), Validators.max(720)]],
    //     maxAntecedence: [0, [Validators.min(0)], Validators.max(720)],
    //     startHour: ['09:00'],
    //     endHour: ['22:00'],
    //   },
    //   {
    //     validators: [this.checkGuests, this.checkIntervals],
    //   }
    // );

    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      minGuests: new FormControl(1, [Validators.min(1), Validators.max(500)]),
      maxGuests: new FormControl(null, [Validators.min(1), Validators.max(500)]),
      category: new FormControl(null, [Validators.required]),
      eventRequired: new FormControl(false),
      fullDay: new FormControl(false),
      adminOnly: new FormControl(false),
      step: new FormControl(null, [Validators.min(10), Validators.max(1440)]),
      minInterval: new FormControl(null, [Validators.min(10), Validators.max(1440)]),
      maxInterval: new FormControl(null, [Validators.min(10), Validators.max(1440)]),
      minAntecedence: new FormControl(0, [Validators.min(0), Validators.max(720)]),
      maxAntecedence: new FormControl(0, [Validators.min(0), Validators.max(720)]),
      startHour: new FormControl('09:00'),
      endHour: new FormControl('22:00'),
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.read(this.id).then(
      (space) => {
        this.loading = false;
        this.errorMessage = null;

        this.space = space;

        this.form.patchValue({
          ['name']: space.name,
          ['minGuests']: space.minGuests,
          ['maxGuests']: space.maxGuests,
          ['adminOnly']: space.adminOnly,
          ['fullDay']: space.fullDay,
          ['eventRequired']: space.eventRequired,
          ['minInterval']: space.minInterval,
          ['maxInterval']: space.maxInterval,
          ['step']: space.step,
          ['minAntecedence']: space.minAntecedence,
          ['maxAntecedence']: space.maxAntecedence,
          ['startHour']: space.startHour,
          ['endHour']: space.endHour,
          ['category']: space.category,
        });
      },
      (_err) => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    this.space = new Space(this.form.value);
    if (this.space.fullDay) {
      this.space.startHour = '09:00';
      this.space.endHour = '22:00';
    }
    this.space.unit = new Unit({ hotelCode: this.hotelInfo.hotelCode });
    this.service.create(this.space).then(
      (space) => {
        this.successNew(space);
        this.loading = false;
        this.errorMessage = null;
      },
      (err) => {
        console.error('Error in create space component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
          if (err.message && err.message.length > 0) {
            for (const e of err.message) {
              this.form.get(e.property).setErrors({
                error: true,
              });
            }
          }
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  update() {
    this.loading = true;
    const space = new Space({
      name: this.form.value.name,
      minGuests: this.form.value.minGuests,
      maxGuests: this.form.value.maxGuests,
      fullDay: this.form.value.fullDay,
      eventRequired: this.form.value.eventRequired,
      adminOnly: this.form.value.adminOnly,
      minInterval: this.form.value.minInterval,
      maxInterval: this.form.value.maxInterval,
      step: this.form.value.step,
      minAntecedence: this.form.value.minAntecedence,
      maxAntecedence: this.form.value.maxAntecedence,
      startHour: (this.form.value.startHour as string).substr(0, 5),
      endHour: (this.form.value.endHour as string).substr(0, 5),
      unit: { hotelCode: this.hotelInfo.hotelCode },
      category: this.form.value.category,
    });
    if (this.space.fullDay) {
      space.startHour = '09:00';
      space.endHour = '22:00';
    }
    this.service.update(space, this.id).then(
      (_space) => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      },
      (err) => {
        console.error('Error in update space component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.errorMessage = 'Ops! Verifique os campos abaixo';
          if (err.message && err.message.length > 0) {
            for (const e of err.message) {
              this.form.get(e.property).setErrors({
                error: true,
              });
            }
          }
        } else if (err.statusCode === 409) {
          this.errorMessage = 'Este email já está registrado para outro usuário. Por favor, escolha outro.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  checkGuests(group: FormGroup) {
    // here we have the 'passwords' group
    const min = group.get('minGuests');
    const max = group.get('maxGuests');
    if (min > max) {
      max.setErrors({ wrongGuestsRange: true });
    }
    return min < max ? null : { wrongGuestsRange: true };
  }

  checkIntervals(group: FormGroup) {
    // here we have the 'passwords' group
    const min = group.get('minInterval');
    const max = group.get('maxInterval');
    if (min > max) {
      max.setErrors({ wrongIntervalsRange: true });
    }
    return min < max ? null : { wrongIntervalsRange: true };
  }

  checkAntecedences(group: FormGroup) {
    const min = group.get('minAntecedence');
    const max = group.get('maxAntecedence');

    if (min > max) {
      max.setErrors({ wrongIntervalsRange: true });
    }

    return min < max ? null : { wrongIntervalsRange: true };
  }

  showError(field?: string) {
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid)
    );
  }

  successNew(space: Space) {
    Swal.fire({
      title: 'Área ' + this.space.name + ' criada!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para área',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then((resp) => {
      if (resp.value) {
        this.goToDetails(space.id);
      } else {
        this.goToForm();
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Espaço editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number, spaceName: string) {
    Swal.fire({
      title: 'Deseja mesmo excluir este espaço?',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then((resp) => {
      if (resp.value) {
        this.delete(id, spaceName);
      }
    });
  }

  delete(id: number, spaceName: string) {
    this.service
      .delete(id)
      .then(() => {
        Swal.fire({
          type: 'success',
          text: 'Espaço ' + spaceName + ' deletado com sucesso.',
          titleText: 'Excluído.',
        }).then((resp) => {
          this.goToList();
        });
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'spaces']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'spaces', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'spaces', 'create']);
  }
}
