import { CanActivate, Router, Route, ActivatedRoute, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { APIService } from '../../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PERMISSIONS } from '../permissions';
import Swal from 'sweetalert2';
import { NavService } from '../service/nav.service';

@Injectable({
  providedIn: 'root'
})

export class RoleRedirect implements CanActivate {
  constructor(private API: APIService, private router: Router, private nav: NavService) { }
  canActivate(childRoute: ActivatedRouteSnapshot) {
    const {path, action} = childRoute.data;
    const user = this.API.loggedUser$.getValue();
    const guardArray = PERMISSIONS[path] ? PERMISSIONS[path][action] : [];
    if (user && guardArray && !guardArray.includes(user.role)) {
      const unit = this.nav.currentUnit$.getValue() ? this.nav.currentUnit$.getValue().code : user.employee.unit.code;
      this.router.navigate(['/unit', unit, 'residents']);
    }
    return true;
  }
}
