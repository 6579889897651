import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  public updatePasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router
  ) {
    this.updatePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPasswordConfirmation: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit() {
  }

updatePassword() {
  this.authService.updatePassword({
    currentPassword: this.updatePasswordForm.value.currentPassword,
    newPassword: this.updatePasswordForm.value.newPassword,
    newPasswordConfirmation: this.updatePasswordForm.value.newPasswordConfirmation
  })
  .then(() => {
    Swal.fire({
      type: 'success',
      text: 'Senha alterada com sucesso!',
      title: 'Alteração de senha',
      showConfirmButton: true,
      onClose: () => this.router.navigate(['/units']),
    });
  },
  (err) => {
      console.error(err);
      Swal.fire({
        type: 'error',
        text: 'Ocorreu um erro...',
        title: 'Ops',
        showConfirmButton: true,
        onClose: () => this.updatePasswordForm.reset(),
      });
  })
}

showError(field: string) {
  return (this.updatePasswordForm.get(field).invalid && this.updatePasswordForm.get(field).touched);
}

isNewPasswordDifferent() {
  if(this.updatePasswordForm.get('newPassword').pristine) {
    return true;
  }
  return this.updatePasswordForm.get('newPassword').value !== this.updatePasswordForm.get('currentPassword').value;
}

verifyNewPasswordConfirmation() {
  if(this.updatePasswordForm.get('newPasswordConfirmation').pristine) {
    return false;
  }
  return this.updatePasswordForm.get('newPassword').value !== this.updatePasswordForm.get('newPasswordConfirmation').value;
}

}

