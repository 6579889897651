import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { DEFAULT_AVATAR, User } from 'src/app/entities/residents/user.entity';
import { VisitsService, Visits, Visitor } from '../visitor.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-details-visit',
  templateUrl: './details-visit.component.html',
  styleUrls: ['./details-visit.component.scss'],
})
export class DetailsVisitComponent implements OnInit {
  visit: Visits = {};
  hotelInfo: Unit;
  loading = false;

  deleting: boolean;

  translate = {
    VISITOR_UNREGISTERED: 'Não registrado',
    PENDING_VISIT: 'Agendado',
    CONFIRMED: 'Autorizado',
    DONE: 'Finalizada',
    CANCELLED: 'Cancelada',
    LATELEAVE: 'Saída atrasada',
  };
  constructor(
    public service: VisitsService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public formBuilder: FormBuilder,
    public toastrSrvc: ToastrService,
    public location: Location,
  ) {}

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe(params => {
      if (params.id) {
        this.visit.id = params.id;
        this.getData(params.id);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
  }

  getData(code: number) {
    this.loading = true;
    this.service
      .readVisit(code)
      .then(visit => {
        this.visit = visit;
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        console.error('Erro ao ler topic', err);
        Swal.fire({
          title: 'Ops, ocorreu um erro',
          text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          showCloseButton: true,
          type: 'error',
        });
      });
  }

  // clickDelete(id: number) {
  //   Swal.fire({
  //     title: 'Deseja mesmo excluir este topic?',
  //     showConfirmButton: true,
  //     confirmButtonText: 'Sim, excluir',
  //     confirmButtonClass: 'btn btn-danger',
  //     showCancelButton: true,
  //     cancelButtonClass: 'btn btn-secondary',
  //     cancelButtonText: 'Cancelar',
  //     showCloseButton: true,
  //     type: 'warning',
  //   }).then(resp => {
  //    if (resp.value) {
  //       this.delete(id);
  //    }
  //   });
  // }

  // delete(id: number) {
  //   this.service.delete(id).then(del => {
  //     Swal.fire({
  //       type: 'success',
  //       text: 'Topic ' + id + ' deletado com sucesso.',
  //       titleText: 'Excluído.'
  //     }).then(resp => {
  //       this.goToList();
  //     });
  //   }).catch(err => {
  //     Swal.fire({
  //       type: 'error',
  //       titleText: 'Erro ao excluir.',
  //       text: 'Por favor, tente novamente.',
  //     });
  //   });
  // }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'topics']);
  }

  async handleDeleteButtonClick() {
    try {
      this.deleting = true;
      await this.service.deleteVisit(this.visit.id);
      this.toastrSrvc.success('Visita excluída com sucesso', 'Visita excluída');
      this.location.back();
    } catch (err) {
      console.error('Error when deleting visit');
    } finally {
      this.deleting = false;
    }
  }

  handleEditButtonClick() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'visits', 'edit', this.visit.id]);
  }
}
