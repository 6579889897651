import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { DateFilterParam, SortingOptionsType, Visits, VisitSorting, visitSortingOptions, VisitsService, VisitStatus, visitStatusOptions } from '../visitor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { ToastrService } from 'ngx-toastr';
import { addDays, isAfter, subDays } from 'date-fns';
import { convertToTimeZone, formatToTimeZone } from 'date-fns-timezone';

@Component({
  selector: 'app-list-visitor',
  templateUrl: './list-visit.component.html',
  styleUrls: ['./list-visit.component.scss'],
})
export class ListVisitsComponent implements OnInit {
  visits: Visits[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;
  translate = {
    VISITOR_UNREGISTERED: 'Não registrado',
    PENDING_VISIT: 'Agendado',
    CONFIRMED: 'Autorizado',
    DONE: 'Finalizada',
    CANCELLED: 'Cancelada',
    LATELEAVE: 'Saída atrasada',
  };
  visitsReport: { total: number; confirmed: number; present: number; lateLeave: string };
  loadingReport: boolean;

  filterByStatusOptions: { label: string; value: VisitStatus }[];
  filterValue: VisitStatus; // todo will accept other values as well (dates for example)
  dateFilterOptions = DateFilterParam;
  currentDateFilter: DateFilterParam;
  filterStartDate: any;
  filterEndDate: any;
  appliedDateFilter: boolean;
  visitStatusOptions = VisitStatus;

  sortEntity: VisitSorting;
  sortOptions: SortingOptionsType[];
  sortValue: SortingOptionsType;
  order: 'ASC' | 'DESC';

  pagination = {
    page: 1,
    limit: 50,
    pages: 5,
    collectionSize: undefined,
  };

  constructor(public route: ActivatedRoute, public navService: NavService, public service: VisitsService, public toastrSrvc: ToastrService, private router: Router) {
    this.sortOptions = visitSortingOptions;
    this.sortValue = {
      value: VisitSorting.CREATED,
    };
    this.filterByStatusOptions = visitStatusOptions;
    this.filterValue = VisitStatus.ALL;

    this.determineDefaultDateFilters();

  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.getAll();
      this.getReports();
    });
  }

  async getReports() {
    try {
      this.loadingReport = true;
      const report = await this.service.getUnitVisitsReport(this.hotelInfo.hotelCode);
      this.visitsReport = report;
    } catch (err) {
      console.error('Error when loading reports -> ', err);
    } finally {
      this.loadingReport = false;
    }
  }

  determineDefaultDateFilters() {
    this.currentDateFilter = DateFilterParam.CREATEDATE;
    const today = new Date();

    const lastFiveDays = subDays(today, 5);
    const nextFiveDays = addDays(today, 5);

    this.filterStartDate = String(lastFiveDays.toISOString()).slice(0, 10);
    this.filterEndDate = String(nextFiveDays.toISOString()).slice(0, 10);

    this.appliedDateFilter = true;
  }

  async getAll() {
    this.loading = true;
    const response = await this.service
      .getVisitsByUnit(
        this.hotelInfo.hotelCode,
        this.pagination.page,
        this.pagination.limit,
        this.sortEntity,
        this.order,
        this.filterValue,
        this.currentDateFilter,
        this.filterStartDate,
        this.filterEndDate,
      )
      .catch((e): any => {
        alert('Erro ao buscar todos os quartos');
        this.loading = false;
        console.error(e);
        return {};
      });

    this.visits = response.data;
    this.checkIfHasLateLeave();
    this.pagination.pages = response.totalPages;
    this.pagination.collectionSize = response.count;
    this.loading = false;
  }

  checkIfHasLateLeave() {
    this.visits = this.visits.map(visit => {
      const formattedDate = convertToTimeZone(new Date(visit.predictedLeaveDate), { timeZone:'America/Recife' });
      const formattedNowDate = new Date();

      if (isAfter(formattedNowDate, formattedDate)  && visit.status === VisitStatus.CONFIRMED) {
        visit.status = VisitStatus.LATELEAVE;
        return visit;
      }
      return visit;
    });

  }

  async handleConfirmEntryButton(visit: Visits, event: MouseEvent) {
    event.stopPropagation();
    try {
      if (!visit || !visit.uuid) {
        return;
      }
      await this.service.confirmEntry(visit.uuid);
      visit.status = VisitStatus.CONFIRMED;

      this.updateLocalState(visit);

      this.toastrSrvc.success('Entrada confirmada');
    } catch (err) {
      this.toastrSrvc.error('Erro ao confirmar entrada do visitante');
    }
  }

  async handleConfirmLeaveButtonClick(visit: Visits, event: MouseEvent) {
    event.stopPropagation();
    try {
      if (!visit || !visit.uuid) {
        return;
      }
      await this.service.confirmLeave(visit.uuid);
      visit.status = VisitStatus.DONE;

      this.updateLocalState(visit);

      this.toastrSrvc.success('Visita encerrada com sucesso', 'Visita encerrada');
    } catch (err) {
      this.toastrSrvc.error('Erro ao confirmar saída do visitante');
    }
  }

  updateLocalState(updatedVisit: Visits) {
    const visitInVisitsListIndex = this.visits.findIndex(visit => visit.id === updatedVisit.id);

    if (visitInVisitsListIndex === -1) {
      console.error('Error when updating local state visit -> ', 'Visit not found');
      return;
    }

    this.visits.splice(visitInVisitsListIndex, 1, updatedVisit);
  }

  async sortVisits(event) {
    const { value, order } = this.sortValue;
    this.order = order;
    this.sortEntity = value;

    await this.getAll();
  }

  async filterVisits(event) {
    if (this.filterValue !== VisitStatus.LATELEAVE) {
      await this.getAll();
      this.visits = this.visits.filter(visit => visit.status !== VisitStatus.LATELEAVE);
    } else {
      this.filterValue = VisitStatus.ALL; // just to make sure we get all visits to filter for the late ones
      await this.getAll();
      this.visits = this.visits.filter(visit => visit.status === VisitStatus.LATELEAVE);
      this.filterValue = VisitStatus.LATELEAVE; // update filter value to match with the late one that was actually selected
    }
  }

  handleDateFilterChange() {
    if (this.currentDateFilter && this.filterStartDate && this.filterEndDate) {
      this.getAll();
      this.appliedDateFilter = true;
    }
  }

  handleClearDateFilterClick() {
    this.currentDateFilter = undefined;
    this.filterStartDate = undefined;
    this.filterEndDate = undefined;
    this.appliedDateFilter = false;

    this.getAll();
  }

  handleCreateNewClick() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'visits', 'create']);
  }

  async handleReportCardClick(status) {
    this.filterValue = status;
    await this.getAll();
  }

  statusBadge(visit: Visits): string {
    switch (visit.status) {
      case VisitStatus.CONFIRMED:
        return 'badge-info';
      case VisitStatus.PENDING_VISIT:
        return 'badge-primary';
      case VisitStatus.DONE:
        return 'badge-success';
      case VisitStatus.CANCELLED:
        return 'badge-light';
      case VisitStatus.LATELEAVE:
        return 'badge-danger';
      default:
        return 'badge-dark';
    }
  }
}
