import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { Room } from '../entities/rooms/room.entity';
import { Unit } from '../entities/units/unit.entity';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(
    public API: APIService
  ) { }

  async getAllFromUnit(hotelCode: number): Promise<Room[]> {
    const rooms = await this.API.get<Room[]>('/rooms', {
      filter: 'unit.HotelCode||eq||' + hotelCode,
      join: 'user',
      sort: ['roomNumber - 0,ASC', 'roomNumber,ASC']
    }, {tokenRequired: true});
    if (rooms && rooms.length) {
      return rooms.map(s => new Room(s));
    }
    return [];
  }

  create(room: Room) {
    room = this.clearNulls(room);
    return this.API.post<Room, Room>('/rooms', room, {tokenRequired: true});
  }

  read(id: number) {
    return this.API.get<Room>(`/rooms/${id}`, {}, {tokenRequired: true});
  }

  update(room: Room, id: number) {
    return this.API.patch<Room, Room>(`/rooms/${id}`, room, {tokenRequired: true});
  }

  delete(id: number) {
    return this.API.delete<Room>(`/rooms/${id}`, {}, {tokenRequired: true});
  }

  clearNulls(room: Room) {
    for (const key in room) {
      if (room[key] === null || room[key] === undefined) {
        delete room[key];
      }
    }
    return room;
  }

  uploadSheet(file: any, hotelCode: number) {
    const formData = new FormData();
    formData.append('file', file);
    return this.API.uploadFile(`/rooms/${hotelCode}/import`, formData, { tokenRequired: true });
  }

  getQR(hotelCode: number) {
    return this.API.get<any>('/cleaning/QR/generateRooms', {hotelCode}, { tokenRequired: true });
  }
}
