import { RestEntity, DeepPartial } from '../rest.entity';
import { Guest } from './guest.entity';
import { Employee } from '../employees/employee.entity';
import { ChatRoom } from '../units/chatRoom.entity';
export const DEFAULT_AVATAR = 'assets/images/avtar/user-avatar.png';

export enum UserStatus {
  PRECREATED = 'PRECREATED',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
}

export enum UserRole {
  User = 'User',
  Admin = 'Admin',
  Guest = 'Guest',
  Manager = 'Manager',
  Porter = 'Porter',
  Cleaning = 'Cleaning',
  Maintenance = 'Maintenance',
  Marketing = 'Marketing',
  Commercial = 'Commercial',
  Doorman = 'Doorman',
  Facilities = 'Facilities',
}

export function translateRole(role: UserRole) {
  switch (role) {
    case UserRole.User:
      return 'Usuário';
    case UserRole.Admin:
      return 'Administrador';
    case UserRole.Marketing:
      return 'Marketing';
    case UserRole.Guest:
      return 'Morador';
    case UserRole.Manager:
      return 'Community Manager';
    case UserRole.Porter:
      return 'Student Relations';
    case UserRole.Cleaning:
      return 'Limpeza';
    case UserRole.Maintenance:
      return 'Manutenção';
    case UserRole.Commercial:
      return 'Comercial';
    case UserRole.Doorman:
      return 'Doorman';
    case UserRole.Facilities:
      return 'Facilities';
    default:
      return 'Usuário';
  }
}

export class User extends RestEntity<User> {
  constructor(obj?: DeepPartial<User>) {
    super(obj);
    Object.assign(this, obj);
  }

  name: string;
  email: string;
  password?: string;
  passwordHash?: string | undefined;
  avatarUrl?: string;
  status: UserStatus;
  role: UserRole;
  guest?: Guest;
  employee?: Employee;
  roomNumber?: string;

  get nickname() {
    return this.name;
  }

  get roleName(): string {
    return translateRole(this.role);
  }

  getAvatar() {
    if (this.avatarUrl) {
      return this.avatarUrl;
    } else {
      return DEFAULT_AVATAR;
    }
  }
}
