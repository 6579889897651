import { RestEntity } from '../rest.entity';
import { User } from 'src/app/entities/residents/user.entity';

export enum SuggestionType {
  SUGGESTION = 'SUGGESTION',
  COMPLAINT = 'COMPLAINT',
}

export enum SuggestionStatus {
  SENT = 'SENT',
  REPLIED = 'REPLIED',
}

export const DEFAULT_AVATAR = 'assets/images/avtar/user-avatar.png';

export class Suggestion extends RestEntity<Suggestion> {

  content: string;
  picUrl: string;
  type: SuggestionType;
  user: User;
  unitHotelCode: number;
  status: SuggestionStatus;
  reply: string;
  replierId: number;
  replier?: User;

  get statusBadge(): {text: string, class: string} {
    if (this.status === SuggestionStatus.SENT) {
      return { text: 'Aberta', class: 'badge-warning'};
    }
    if (this.status === SuggestionStatus.REPLIED) {
      return { text: 'Respondida', class: 'badge-success'};
    }
  }

  get typeTranslation(): string {
    switch (this.type) {
      case SuggestionType.SUGGESTION:
        return 'Sugestão';
      case SuggestionType.COMPLAINT:
        return 'Reclamação';
    }
  }

  getPic() {
    if (this.picUrl) {
      return this.picUrl;
    } else {
      return DEFAULT_AVATAR;
    }
  }
}
