import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import Swal from 'sweetalert2';
import { DEFAULT_AVATAR, User, UserRole } from 'src/app/entities/residents/user.entity';
import { ResidentsService } from '../residents.service';
import { ChatRoom } from 'src/app/entities/units/chatRoom.entity';
import { ChatService } from 'src/app/shared/service/chat.service';

@Component({
  selector: 'app-details-resident',
  templateUrl: './details-resident.component.html',
  styleUrls: ['./details-resident.component.scss'],
})
export class DetailsResidentComponent implements OnInit {
  roles = UserRole;
  resident: Guest = new Guest();
  hotelInfo: Unit;
  loading = false;
  uploadForm: FormGroup;
  avatar: string | ArrayBuffer = this.resident.avatarUrl;
  bgAvatar = false;
  chat: ChatRoom;
  constructor(
    public service: ResidentsService,
    public chatService: ChatService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe((params) => {
      if (params.residentId) {
        this.resident.GuestCode = params.residentId;
        this.getData(params.residentId);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
    this.uploadForm = this.formBuilder.group({
      avatar: [''],
    });
  }

  getData(code: number) {
    this.loading = true;
    this.service
      .read(code)
      .then(async (resident) => {
        this.resident = new Guest(resident);
        if (this.resident.hasAvatar()) {
          this.avatar = this.resident.avatarUrl;
          this.bgAvatar = true;
        }
        const chatObs = await this.chatService.getRooms();
        chatObs.subscribe((rooms) => {
          this.chat = rooms.find((value) => value.userTarget.id === this.resident.user.id);
        });
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.error('Erro ao ler resident', err);
        Swal.fire({
          title: 'Ops, ocorreu um erro',
          text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          showCloseButton: true,
          type: 'error',
        });
      });
  }

  updateAvatar() {
    this.service.setAvatar(this.resident.user.id, this.uploadForm.get('avatar').value).then(
      (res) => {
        Swal.fire({
          type: 'success',
          text: 'Foto de perfil atualizada.',
          titleText: 'Feito!',
        });
        this.uploadForm.reset();
        this.resident.user.avatarUrl = res.avatarUrl;
        this.avatar = this.resident.avatarUrl;
        this.bgAvatar = true;
      },
      (err) => {
        console.error('Erro no upload', err);
        if (err.statusCode === 413) {
          Swal.fire({
            titleText: 'Imagem muito grande!',
            text: 'Por favor, envie imagens com menos de 2MB',
            type: 'error',
          });
          this.uploadForm.reset();
          this.avatar = DEFAULT_AVATAR;
        } else {
          Swal.fire({
            titleText: 'Ops!',
            text: 'Ocorreu um erro. Por favor, tente novamente ou entre em contato com a equipe de desenvolvimento',
            type: 'error',
          });
        }
      },
    );
  }

  deleteAvatar() {
    Swal.fire({
      titleText: 'Deseja mesmo remover a imagem de perfil deste usuário?',
      text: 'Esta ação não poderá ser desfeita.',
      type: 'question',
      showCancelButton: true,
      cancelButtonText: 'Manter foto',
      focusCancel: true,
      cancelButtonClass: 'float-left btn btn-primary',
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Remover',
      confirmButtonClass: 'float-right btn btn-danger',
    }).then(async (resp) => {
      if (resp.value) {
        await this.service.update(
          new Guest({
            user: new User({
              id: this.resident.user.id,
              avatarUrl: null,
            }),
            GuestCode: this.resident.GuestCode,
          }),
          this.resident.GuestCode,
        );
        this.resident.user.avatarUrl = null;
        this.avatar = DEFAULT_AVATAR;
        this.bgAvatar = false;

        Swal.fire({
          type: 'success',
          text: 'Foto de perfil removida.',
          titleText: 'Pronto!',
        });
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('avatar').setValue(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.avatar = reader.result;
        this.bgAvatar = true;
      };
    }
  }

  resetForm() {
    this.uploadForm.reset();
    this.avatar = DEFAULT_AVATAR;
    this.bgAvatar = false;
  }

  async openChat() {
    if (!this.chat) {
      this.chat = (await this.chatService.create(this.resident.user.id)) as any;
    }
    return this.router.navigate(['units', 'chat', this.chat.id]);
  }

  async deleteResidentClick() {
    Swal.fire({
      title: 'Tem certeza que quer deletar o morador ' + this.resident.fullname + '?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then((resp) => {
      if (resp.value) {
        this.delete(this.resident.GuestCode);
      }
    });
  }

  async checkoutResidentClick() {
    Swal.fire({
      title: 'Tem certeza realizar o checkout do morador ' + this.resident.fullname + '?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Checkout',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then((resp) => {
      if (resp.value) {
        this.checkout(this.resident.GuestCode);
      }
    });
  }

  async checkout(guestCode: number) {
    this.service
      .checkout(guestCode)
      .then((guest) => {
        Swal.fire({
          type: 'success',
          text: 'Checkout do morador ' + guestCode + ' realizado com sucesso.',
          titleText: 'Checkout',
        }).then((resp) => {});
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao realizar checkout.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  delete(guestCode: number) {
    this.service
      .delete(guestCode)
      .then((del) => {
        Swal.fire({
          type: 'success',
          text: 'Morador ' + guestCode + ' deletado com sucesso.',
          titleText: 'Excluído.',
        }).then((resp) => {
          this.goToList();
        });
      })
      .catch((err) => {
        Swal.fire({
          type: 'error',
          titleText: 'Erro ao excluir.',
          text: 'Por favor, tente novamente.',
        });
      });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'residents']);
  }
}
