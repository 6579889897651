import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SuggestionsService } from '../suggestions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Unit } from 'src/app/entities/units/unit.entity';
import Swal from 'sweetalert2';
import { User, DEFAULT_AVATAR } from '../../entities/residents/user.entity';
import { Suggestion, SuggestionStatus, SuggestionType } from 'src/app/entities/units/suggestions.entity';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-details-suggestions',
  templateUrl: './details-suggestions.component.html',
  styleUrls: ['./details-suggestions.component.scss']
})
export class DetailsSuggestionsComponent implements OnInit {

  suggestion: Suggestion = new Suggestion();
  hotelInfo: Unit;
  loading = false;
  uploadForm: FormGroup;
  response = '';
  user: User;

  constructor(
    public service: SuggestionsService,
    public router: Router, private modalService: NgbModal,
    public route: ActivatedRoute, public authService: AuthService,
    public navService: NavService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.suggestion.id = params.id;
        this.getData(params.id);
      } else {
        this.router.navigate(['error', '404']);
      }
    });
    this.authService.getLoggedProfile().subscribe((usr) => {
      this.user = usr;
    });
    this.uploadForm = this.formBuilder.group({
      // TODO update
    });
  }

  getData(id: number) {
    this.loading = true;
    this.service.read(id).then((sggs) => {
      this.suggestion = new Suggestion(sggs);
      this.loading = false;
  }).catch((err) => {
      this.loading = false;
      console.error('Erro ao ler sugestão', err);
      Swal.fire({
        title: 'Ops, ocorreu um erro',
        text: 'Por favor, tente novamente ou contate a equipe de desenvolvimento',
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        showCloseButton: true,
        type: 'error'
      });
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    });
  }

  respond() {
    this.suggestion.reply = this.response;
    this.suggestion.replierId = this.user.id;
    this.suggestion.status = SuggestionStatus.REPLIED;
    return this.service.update(this.suggestion, this.suggestion.id);
  }

  async deleteSuggestionsClick() {
    Swal.fire({
      title: 'Tem certeza que quer deletar esse feedback?',
      text: 'Esta ação não poderá ser desfeita',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonClass: 'btn btn-danger',
      cancelButtonClass: 'btn btn-secondary',
    }).then(resp => {
      if (resp.value) {
         this.delete(this.suggestion.id);
      }
     });
  }

  delete(sugestionCode: number) {
    this.service.delete(sugestionCode).then(del => {
      Swal.fire({
        type: 'success',
        text: 'Feedback deletado com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
          this.goToSugestion();
        }
      );
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }
  goToSugestion() {
    if (this.suggestion.type === SuggestionType.SUGGESTION) {
      this.router.navigate(['/unit', this.hotelInfo.code, 'feedback', 'suggestions']);
    } else {
      this.router.navigate(['/unit', this.hotelInfo.code, 'feedback', 'complainings']);
    }
  }
}
