import { Injectable } from '@angular/core';
import { APIService, APIPaginationdResponse } from '../api.service';
import { UserEvent } from './event.entity';
import { Schedule, Attendance } from '../entities/spaces/schedule.entity';
import { User } from '../entities/residents/user.entity';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(public API: APIService) {}

  async getAllFromUnit(hotelCode: number, query = '', pagination?: { limit: number; page: number }): Promise<APIPaginationdResponse<UserEvent>> {
    const events = await this.API.get<APIPaginationdResponse<UserEvent>>(
      '/events/getFromUnit?' + query,
      {
        hotelCode,
        limit: pagination.limit,
        page: pagination.page,
        sort: 'startDate,ASC',
      },
      { tokenRequired: true },
    );
    events.data.map(e => {
      e.user = new User(e.user);
      return new UserEvent(e);
    });
    return events;
  }

  create(event: UserEvent) {
    event = this.clearNulls(event);
    return this.API.post<UserEvent, UserEvent>('/events', event, { tokenRequired: true });
  }

  read(id: number, params = {}) {
    return this.API.get<UserEvent>(`/events/${id}`, params, { tokenRequired: true });
  }

  update(events: UserEvent, id: number) {
    return this.API.put<UserEvent, UserEvent>(`/events/${id}`, events, { tokenRequired: true });
  }

  confirmPresence(id: number, attendance: Attendance, presence: number) {
    return this.API.patch<Attendance>(`/events/${id}/attend/${presence}`, attendance, { tokenRequired: true, hideLoading: true });
  }

  delete(id: number) {
    return this.API.delete<UserEvent>(`/events/${id}`, {}, { tokenRequired: true });
  }

  async getAll(hotelCode: number, params: { startDate?: string | Date; page?: number; limit?: number }) {
    const queryParams = {
      page: params.page ? String(params.page) : '1',
      limit: params.limit ? String(params.limit) : '1',
      hotelCode: String(hotelCode),
    };

    if (params.startDate) {
      Object.assign(queryParams, { startDate: (params.startDate as Date).toISOString() });
    }

    const events = await this.API.get(`/events`, queryParams, { tokenRequired: true });
    return events.data;
  }

  clearNulls(event: UserEvent) {
    for (const key in event) {
      if (event[key] === null || event[key] === undefined) {
        delete event[key];
      }
    }
    return event;
  }
}
