import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Unit } from 'src/app/entities/units/unit.entity';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { VouchersService, Voucher } from '../vouchers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vouchers-form',
  templateUrl: './vouchers-form.component.html',
  styleUrls: ['./vouchers-form.component.scss'],
})
export class VoucherFormComponent implements OnInit {
  form: FormGroup;
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  id: number = undefined;
  voucher: Voucher;
  hasDescription: boolean = true;
  voucherLogoUrl: string | ArrayBuffer;
  pageMode: 'new' | 'edit' = 'new';
  file: any;
  units: Unit[];

  constructor(private formBuilder: FormBuilder, public service: VouchersService, public router: Router, public route: ActivatedRoute, public navService: NavService) {}

  ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.initCreateForm();
    /** @TODO if is update, initUpdateForm  */

    this.getUnits();

    this.route.params.subscribe(params => {
      if (params.voucherId) {
        this.id = params.voucherId;
        this.pageMode = 'edit';
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.pageMode = 'new';
        this.initCreateForm();
      }
    });
  }

  initCreateForm() {
    this.form = new FormGroup({
      title: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      unit: new FormControl('undefined', [Validators.required]),
      description: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      partnerName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      buttonUrl: new FormControl(null),
      hasDescription: new FormControl(true),
      priority: new FormControl('10'),
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.service.loadVoucherById(this.id).then(
      voucher => {
        this.loading = false;
        this.errorMessage = null;

        this.voucherLogoUrl = voucher.logoUrl;
        this.hasDescription = voucher.description? true: false;

        this.form.patchValue({
          ['title']: voucher.title,
          ['description']: voucher.description,
          ['unit']: voucher.showToAllUnits? "all" : voucher.unit.hotelCode,
          ['partnerName']: voucher.partnerName,
          ['hasDescription']: this.hasDescription,
          ['buttonUrl']: voucher.buttonUrl,
          ['priority']: voucher.priority,
        });
      },
      _err => {
        this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
      },
    );
  }

  submit() {
    if (this.hasDescription) {
      this.form.value.buttonUrl = null;
    } else {
      this.form.value.description = null;
    }

    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    this.loading = true;
    const formData = this.form.value;
    formData.showToAllUnits = formData.unit == 'all';
    this.service.createNewVoucher(formData, this.file).then(
      voucher => {
        this.successNew(voucher);
        this.loading = false;
        this.errorMessage = null;
      },
      err => {
        console.error('Error in create voucher component', err);
        this.loading = false;
        if (err.error === 400) {
          this.handleErrors(err.error);
          if (err.message && err.message.length > 0) {
            for (const e of err.message) {
              this.form.get(e.property).setErrors({
                error: true,
              });
            }
          }
        } else if (err.statusCode === 404) {
          this.errorMessage = 'Ocorreu um erro ao identificar a unidade. Tente novamente, por favor.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      },
    );
  }

  successNew(voucher: Voucher) {
    Swal.fire({
      title: `Voucher ${voucher.title} criado com sucesso`,
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ir para o voucher',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outro',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (resp.value) {
        this.goToDetails(voucher.id);
      } else {
        this.goToForm();
      }
    });
  }

  update() {
    this.loading = true;
    const formData = this.form.value;
    formData.showToAllUnits = formData.unit == 'all';
    this.service
      .editVoucher(this.id, formData, this.file)
      .then(voucher => {
        this.successEdit();
        this.loading = false;
        this.errorMessage = null;
      })
      .catch(err => {
        console.error('Error in update space component', err);
        this.loading = false;
        if (err.statusCode === 400) {
          this.handleErrors(err.error);
          if (err.message && err.message.length > 0) {
            for (const e of err.message) {
              this.form.get(e.property).setErrors({
                error: true,
              });
            }
          }
        } else if (err.statusCode === 404) {
          this.errorMessage = 'Ocorreu um erro ao identificar a unidade. Tente novamente, por favor.';
        } else {
          this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
        }
      });
  }

  successEdit() {
    return Swal.fire({
      title: 'Voucher editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'vouchers']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'vouchers', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'vouchers', 'create']);
  }

  showError(field?: string) {
    return (
      (this.form.get(field).invalid && this.form.get(field).touched) ||
      (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid)
    );
  }

  // FILE UPLOADING
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file = file;
      // this.uploadForm.get('avatar').setValue(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) === null) {
        alert('Only images (.jpg, .jpeg, .png) are supported.');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = _event => {
        this.voucherLogoUrl = reader.result;
        // this.bgAvatar = true;
      };
    }
  }

  description() {
    this.hasDescription = this.form.value.hasDescription;
  }

  async getUnits() {
    this.units = await this.service.loadAllUnits();
  }

  handleErrors(error) {
    if (error === 'CLEAN_TOO_SOON') {
      return this.errorMessage = 'É necessário solicitar a limpeza com uma maior antecedência';
    } else if (error === 'IMAGE_REQUIRED') {
      return this.errorMessage = 'É necessário que uma imagem seja escolhida para o voucher.';
    } else if (error === 'TITLE_REQUIRED') {
      return this.errorMessage = 'É necessário informar um titulo para o voucher';
    } else if (error === 'DESCRIPTION_REQUIRED') {
      return this.errorMessage = 'É necessário informar uma descrição ao voucher';
    } else if (error === 'INCORRECT_PRIORITY') {
      return this.errorMessage = 'É necessário escolher a prioridade entre 0 e 10!';
    } else {
      return this.errorMessage = 'Ops! Verifique os campos abaixo'
    }
  }
}
