import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../../shared/service/chat.service';
import { ChatRoom } from 'src/app/entities/units/chatRoom.entity';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/entities/residents/user.entity';
import { ActivatedRoute } from '@angular/router';
import Client from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';


@Component({
  selector: 'app-chat-app',
  templateUrl: './chat-app.component.html',
  styleUrls: ['./chat-app.component.scss']
})
export class ChatAppComponent implements OnInit {

  public chatRooms: ChatRoom[];
  public openedChat: ChatRoom;
  public loggedUser: User;
  public loading = true;
  public client: Client;
  public channel: Channel;
  public channelMessages: any;
  public isTyping: boolean;
  public msg: string;
  public error = false;

  constructor(private chatService: ChatService, private authService: AuthService,
              public route: ActivatedRoute) {

    ///////////
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.loadChat(params.id);
      }
    });
    this.authService.getLoggedProfile().subscribe((usr) => this.loggedUser = usr);
    this.loadList();
  }

  async loadChat(id: number) {
    await this.chatService.getChats();
    this.openedChat = this.chatService.getRoom(Number(id));
    await this.getClient();
    await this.getDirectChannel(this.openedChat);
    await this.getMessages();
    this.loading = false;

    this.addListeners();
  }

  async getClient() {
    this.client = await this.chatService.getClient();
  }

  async getDirectChannel(chat) {
    this.channel = await this.client.getChannelBySid(chat.twilioChannelSid);
  }

  async getMessages() {
    this.channelMessages = (await this.channel.getMessages());
    // TODO implement scroll
    await this.channel.setAllMessagesConsumed();
    this.scrollDown();
    this.loading = false;
  }

  async addListeners() {
    this.channel.on('messageAdded', (message) => {
      this.getMessages();
      this.scrollDown();
    });

    this.channel.on('messageRemoved', () => {
      this.getMessages();
    });

    this.channel.on('messageUpdated', () => {
      this.getMessages();
    });

    this.channel.on('typingStarted', (member) => {
      this.isTyping = true;
      this.scrollDown();
    });

    this.channel.on('typingEnded', (member) => {
      this.isTyping = false;
      this.scrollDown();
    });
  }

  scrollDown() {
    // document.querySelector('#chat-hst').
    setTimeout(() => {
      document.querySelector('#chat-hst').scrollTo(0, document.querySelector('#chat-hst').scrollHeight);
    }, 100);
  }

  async loadList() {
    const chatObs = await this.chatService.getRooms();
    chatObs.subscribe(rooms => this.chatRooms = rooms);
    this.route.params.subscribe((params) => {
      if (params.id && this.chatRooms) {
        this.openedChat = this.chatRooms.find(value => value.id === Number(params.id));
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  }

  ngOnInit() {}

  // Send Message to User
  async sendMessage() {
    // TODO set focus on input
    if (!this.msg) {
      return;
    }
    const text = this.msg;
    this.msg = '';
    await this.channel.sendMessage(text);
  }

  searchTerm(term: any) {
    // TODO search
    // if (!term) return this.searchUsers = this.users
    // term = term.toLowerCase();
    // let user = []
    // this.users.filter(users => {
    //   if (users.name.toLowerCase().includes(term)) {
    //     user.push(users)
    //   }
    // })
    // this.searchUsers = user
  }

}
