export enum ErrorsEnum  {
  "DATE_CONFLICT" = "DATE_CONFLICT",
  "SPACE_NOT_FOUND" = "SPACE_NOT_FOUND",
  "SCHEDULE_LIMIT_EXCEEDED_ALLOWED" = "SCHEDULE_LIMIT_EXCEEDED_ALLOWED",
  "HAS_UNFINISHED_SCHEDULE" = "HAS_UNFINISHED_SCHEDULE",
  "START_DATE" = "START_DATE",
  "END_DATE" = "END_DATE",
  "MIN_INTERVAL" = "MIN_INTERVAL",
  "MAX_INTERVAL" = "MAX_INTERVAL",
  "EVENT_NOT_FOUND" = "EVENT_NOT_FOUND",
  "NOT_OWNED_EVENT" = "NOT_OWNED_EVENT",
  "INVITE_NOT_FOUND" = "INVITE_NOT_FOUND",
  "INVITE_ALREADY_ACCEPTED" = "INVITE_ALREADY_ACCEPTED",
  "INVITE_ALREADY_REFUSED" = "INVITE_ALREADY_REFUSED",
  "NOT_OWN_INVITE" = "NOT_OWN_INVITE",
  "NOT_OWNED_INVITE" = "NOT_OWNED_INVITE",
  "NOT_AN_INVITE" = "NOT_AN_INVITE",
  "UNIT_NOT_FOUND" = "UNIT_NOT_FOUND",
  "EVENT_IS_PRIVATE" = "EVENT_IS_PRIVATE",
  "NO_OWN_EVENT_JOIN" = "NO_OWN_EVENT_JOIN",
  "USER_NOT_IN_EVENT" = "USER_NOT_IN_EVENT",
  "ALREADY_LEFT_EVENT" = "ALREADY_LEFT_EVENT",
  "INVALID_PAGE" = "INVALID_PAGE",
  "FILE_EMPTY" = "FILE_EMPTY",
  "UPLOAD_ERROR" = "UPLOAD_ERROR",
  "GUEST_NOT_FOUND" = "GUEST_NOT_FOUND",
  "EMAIL_NOT_REGISTERED" = "EMAIL_NOT_REGISTERED",
  "EMAIL_ALREADY_REGISTERED" = "EMAIL_ALREADY_REGISTERED",
  "EMAIL_NOT_FOUND" = "EMAIL_NOT_FOUND",
  "ERR_AUTH_LOGIN_WRONG_CREDENTIALS" = "ERR_AUTH_LOGIN_WRONG_CREDENTIALS",
  "USER_ALREADY_ACTIVE" = "USER_ALREADY_ACTIVE",
  "ROOM_NUMBER_MISSING" = "ROOM_NUMBER_MISSING",
  "ROOM_NOT_FOUND" = "ROOM_NOT_FOUND",
  "ROOM_NOT_AVAILABLE" = "ROOM_NOT_AVAILABLE",
}

export const ErrorsTranslated = {
  [ErrorsEnum.DATE_CONFLICT]: "Esse horário já está reservado",
  [ErrorsEnum.SPACE_NOT_FOUND]: "Espaço não encontrado",
  [ErrorsEnum.SCHEDULE_LIMIT_EXCEEDED_ALLOWED]: "Limite de reservas atingido",
  [ErrorsEnum.HAS_UNFINISHED_SCHEDULE]: "Você possui uma reserva não concluída",
  [ErrorsEnum.START_DATE]: "Data de inicio inválida",
  [ErrorsEnum.END_DATE]: "Data final inválida",
  [ErrorsEnum.MIN_INTERVAL]: "Intervalo mínimo inválido",
  [ErrorsEnum.MAX_INTERVAL]: "Intervalo máximo inválido",
  [ErrorsEnum.EVENT_NOT_FOUND]: "Reserva não encontrada",
  [ErrorsEnum.NOT_OWNED_EVENT]: "Você só pode enviar convites para seus próprios eventos",
  [ErrorsEnum.INVITE_NOT_FOUND]: "Convite não encontrado",
  [ErrorsEnum.INVITE_ALREADY_ACCEPTED]: "Convite já aceito",
  [ErrorsEnum.INVITE_ALREADY_REFUSED]: "Convite já rejeitado",
  [ErrorsEnum.NOT_OWN_INVITE]: "Não autorizado",
  [ErrorsEnum.NOT_OWNED_INVITE]: "Não autorizado",
  [ErrorsEnum.NOT_AN_INVITE]: "Não é possível cancelar",
  [ErrorsEnum.UNIT_NOT_FOUND]: "Unidade não encontrada",
  [ErrorsEnum.EVENT_IS_PRIVATE]: "Você não pode participar deste evento",
  [ErrorsEnum.NO_OWN_EVENT_JOIN]: "Você não pode ser convidado do seu próprio evento.",
  [ErrorsEnum.USER_NOT_IN_EVENT]: "Voce não faz parte deste evento",
  [ErrorsEnum.ALREADY_LEFT_EVENT]: "Voce já saiu deste evento",
  [ErrorsEnum.INVALID_PAGE]: "Número de página inválido",
  [ErrorsEnum.FILE_EMPTY]: "Arquivo não pode ser vazio",
  [ErrorsEnum.UPLOAD_ERROR]: "Erro ao enviar arquivo",
  [ErrorsEnum.GUEST_NOT_FOUND]: "Usuário não encontrado",
  [ErrorsEnum.EMAIL_NOT_REGISTERED]: "Email inválido",
  [ErrorsEnum.EMAIL_ALREADY_REGISTERED]: "Email já registrado",
  [ErrorsEnum.EMAIL_NOT_FOUND]: "Email não encontrado",
  [ErrorsEnum.ERR_AUTH_LOGIN_WRONG_CREDENTIALS]: "Email ou senha incorretos",
  [ErrorsEnum.USER_ALREADY_ACTIVE]: "Usuário já possui conta ativa",
  [ErrorsEnum.ROOM_NUMBER_MISSING]: "Número de quarto não informado",
  [ErrorsEnum.ROOM_NOT_FOUND]: "Quarto não encontrado",
  [ErrorsEnum.ROOM_NOT_AVAILABLE]: "Quarto não disponível",

}
