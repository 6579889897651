import { Component, OnInit } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { AreasService } from '../areas.service';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/service/nav.service';
import { Area } from 'src/app/entities/units/area.entity';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-areas',
  templateUrl: './list-areas.component.html',
  styleUrls: ['./list-areas.component.scss']
})
export class ListAreasComponent implements OnInit {

  areas: Area[] = [];
  searchTerm = '';
  loading = true;
  hotelInfo: Unit;

  qrList = {};
  hasQrList = false;

  constructor(
    public service: AreasService,
    public route: ActivatedRoute,
    public navService: NavService,
  ) {
  }

  ngOnInit() {
    this.navService.currentUnit$.subscribe((unit) => {
      this.hotelInfo = unit;

      this.getAll();
    });
  }

  async getAll() {
    this.loading = true;
    this.areas = await this.service.getAllFromUnit(this.hotelInfo.hotelCode)
    .catch((e) => {
      alert('Erro ao buscar todos os quartos');
      this.loading = false;
      console.error(e);
      return [];
    });
    this.loading = false;
  }

  async getQR() {
    this.loading = true;
    this.qrList = await this.service.getQR(this.hotelInfo.hotelCode);
    this.hasQrList = true;
  }

  async printQR() {
    await this.getQR();
    setTimeout(() => {
      let pagehtml = '';
      for (const key in this.qrList) {
        if (this.qrList.hasOwnProperty(key)) {
          const obj = this.qrList[key];
          const img = document.querySelector(`qrcode[title="${obj.QR}"] img`);
          img.setAttribute('style', 'display: initial;');
          pagehtml += `<div style='text-align: center;
                        width: 45%;
                        padding: 8px;
                        float: right;
                        color: rgb(241, 128, 65);'> `;
          pagehtml += '<div style="border: 2px dashed black;padding:8px">';
          pagehtml += img.outerHTML + ' ';
          pagehtml += `<p><img src="https://i.imgur.com/F0zipA9.png" height="20" style="position: relative;top: 5px;"/> <b>Uliving</b> - `;
          pagehtml += `Área ${key}: ${obj.name}`;
          pagehtml += '</p></div></div>';
          img.setAttribute('style', 'display: initial;max-height: 20px;');
        }
      }
      try {
        const popup = window.open();
        popup.document.write(pagehtml);
        popup.focus();
        setTimeout(() => {
          popup.print();
        }, 500);
      } catch (e) {
        Swal.fire({
          titleText: 'Erro',
          html: '<p>Favor checar se é possivel abrir pop-up desta página</p>',
          backdrop: true,
          showCloseButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: false,
        });
      }
      this.loading = false;
    }, 100); // waiting for the angularx-qrcode to load the imgs
  }

}
