import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UserEvent } from '../event.entity';
import { EventsService } from '../events.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Space } from 'src/app/entities/spaces/space.entity';
import { Guest } from 'src/app/entities/residents/guest.entity';
import { Schedule } from 'src/app/entities/spaces/schedule.entity';
import { User } from 'src/app/entities/residents/user.entity';
import { Unit } from 'src/app/entities/units/unit.entity';
import { SpacesService } from 'src/app/unit/spaces.service';
import { SchedulesService } from 'src/app/unit/schedules.service';
import { Attendance } from 'src/app/entities/spaces/schedule.entity';
import { UnitsService } from 'src/app/unit/units.service';
import { ResidentsService } from 'src/app/residents/residents.service';
import { AuthService } from 'src/app/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { addHours } from 'date-fns';
import Swal from 'sweetalert2';
import { ErrorsTranslated } from 'src/app/shared/errorMessagesTranslation';
import { dateRangeValidator } from 'src/app/shared/validators/date.validator';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit, OnChanges {

  form: FormGroup;
  spaces: Space[] = [];
  residents: Guest[] = [];
  attendants: Attendance [] = [];
  schedules: Schedule[] = [];
  event: UserEvent = new UserEvent();
  attendees: User[];
  admin: User = null;
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  @Input() id: number = undefined;
  @Input() isModal = false;
  constructor(
    private formBuilder: FormBuilder,
    public spacesService: SpacesService,
    public schedulesService: SchedulesService,
    public eventsService: EventsService,
    public unitsService: UnitsService,
    public residentsService: ResidentsService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService) {
  }


  async ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;
    this.initCreateForm();

    this.route.params.subscribe(async (params) => {
      if(!this.hotelInfo) return;
      const [schedules, allResidents] = await Promise.all([
        this.schedulesService.getFutureFromUnit(this.hotelInfo.hotelCode, 1, !!!params.id),
        this.residentsService.getAllGuests(this.hotelInfo.hotelCode),
      ]);

      this.spaces = this.navService.hotelInfo.spaces;
      this.schedules = schedules;
      this.residents = allResidents.data.filter(r => r.user ? true : false);

      if (params.id) {
        this.id = params.id;
        this.initUpdateForm();
      }
    });

    this.authService.getLoggedProfile().subscribe(user => {
      this.admin = user;
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.id.currentValue !== changes.id.previousValue) {
      this.initUpdateForm();
    }
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      scheduleId: [null],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      userId: [null, [Validators.required]],
      private: [false],
      title: [null, Validators.required],
      description: [null, Validators.required]
    }, {
      validators: [
        dateRangeValidator("startDate", "endDate"),
      ]
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.eventsService.read(this.id, {}).then(event => {
      this.loading = false;
      this.errorMessage = null;

      this.event = new UserEvent(event);
      this.event.startDate = addHours(this.event.startDate, -3);
      this.event.endDate = addHours(this.event.endDate, -3);

      this.form.patchValue({
        ['scheduleId']: this.event.schedule ? this.event.schedule.id : null,
        ['startDate']: String(this.event.startDate.toISOString()).slice(0, 16),
        ['endDate']: String(this.event.endDate.toISOString()).slice(0, 16),
        ['userId']: this.event.user.id,
        ['private']: this.event.private,
        ['title']: this.event.title,
        ['description']: this.event.description,
      });
    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    Object.keys(this.form.controls).forEach(field => { // {1}
      const control = this.form.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });

    // if (this.form.invalid) return

    let eventPrivate: boolean

    if (typeof this.form.value.private === 'string') {
      eventPrivate = this.form.value.private === 'true';
    } else {
      eventPrivate = this.form.value.private;
    }

    this.loading = true;
    this.event = new UserEvent({
      schedule: {id: this.form.value.scheduleId},
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      user: {id: this.form.value.userId},
      private: eventPrivate,
      title: this.form.value.title,
      description: this.form.value.description,
      unit: {hotelCode: this.hotelInfo.hotelCode}
    });

    const event = this.event;
    if (event.schedule && isNaN(Number(event.schedule.id))) {
      delete event.schedule;
    }
    this.eventsService.create(this.event).then(newEvent => {
      this.successNew(newEvent);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create event component', err);
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Erro!',
        text:ErrorsTranslated[err.error] ? ErrorsTranslated[err.error] : 'Verifique os campos novamente.',
      });
    });
  }

  confirmPresence( id, e ) {
    let presence;
    if (e.target.checked) {
      presence = 1; } else {presence = 0; }

    const attendance = {
      id: id,
      presence: presence,
    };

    this.eventsService.confirmPresence(id, attendance, presence).then(() => {
      return;
    // tslint:disable-next-line: no-unused-expression
    }), (err: any) => {
      console.error('Error in update presence component', err);
      return;
    };
  }

  update() {
    Object.keys(this.form.controls).forEach(field => { // {1}
      const control = this.form.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });

    // if (this.form.invalid) return

    let eventPrivate: boolean
    if (typeof this.form.value.private === 'string') {
      eventPrivate = this.form.value.private === 'true';
    } else {
      eventPrivate = this.form.value.private;
    }

    this.loading = true;
    const event = new UserEvent({
      id: this.id,
      schedule: {id: this.form.value.scheduleId},
      startDate: this.form.value.startDate,
      endDate: this.form.value.endDate,
      user: {id: this.form.value.userId},
      private: eventPrivate,
      title: this.form.value.title,
      description: this.form.value.description,
    });

    if (event.schedule && isNaN(Number(event.schedule.id)) && (event.schedule.id as any) !== 'undefined') {
      delete event.schedule;
    }

    if ((event.schedule.id as any) === 'undefined') {
      event.schedule = undefined;
    }

    this.eventsService.update(event, this.id).then(() => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update event component', err);
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Erro!',
        text: ErrorsTranslated[err.error] ? ErrorsTranslated[err.error] : 'Verifique os campos novamente.',
      });
    });
  }

  showError(field?: string) {
    return (this.form.get(field) && this.form.get(field).invalid && this.form.get(field).touched) ||
           (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  showDateRangeError(field?: 'startDate' | 'endDate') {
    if (!this.form.get(field)) {
      return false;
    }

    if(this.form.get(field).errors && !this.form.get(field).errors.required) {
      return true;
    };
    };

  successNew(event: UserEvent) {
    Swal.fire({
      title: 'Evento criado!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (this.isModal) {
        // this.closeModal();
      } else {
        if (resp.value) {
          this.goToDetails(event.id);
        } else {
          this.goToForm();
        }
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Evento editado!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number, eventTitle: string) {
    Swal.fire({
      title: 'Deseja mesmo excluir este evento',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
     if (resp.value) {
        this.delete(id, eventTitle);
     }
    });
  }

  delete(id: number, eventTitle: string) {
    this.eventsService.delete(id).then(() => {
      Swal.fire({
        type: 'success',
        text: 'Evento ' + eventTitle + ' deletado com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
        this.goToList();
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }

  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'events', 'all']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'events', 'all', id, 'edit']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'events', 'all', 'create']);
  }

}
