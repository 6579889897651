import { Injectable } from '@angular/core';
import { Unit } from '../entities/units/unit.entity';
import { Cleaning } from '../entities/cleaning/cleaning.entity';
import { User } from '../entities/residents/user.entity';
import { APIService, APIPaginationdResponse } from '../api.service';
import { RestEntity, DeepPartial } from '../entities/rest.entity';
import { Employee } from '../entities/employees/employee.entity';
import { IssueCategory } from '../issue-categories/issue-category.entity';
import { distanceInWords, distanceInWordsStrict, differenceInHours } from 'date-fns';
import * as localeBR from 'date-fns/locale/pt';
import { Room } from '../entities/rooms/room.entity';
export const DEFAULT_AVATAR = '../../assets/images/avtar/user-avatar.png';

export enum MaintenanceArea {
  APARTMENT = 'APARTMENT',
  COMMON = 'COMMON',
}

export enum MaintenanceType {
  ELETRIC = 'ELETRIC',
  MECHANIC = 'MECHANIC',
  HIDRAULIC = 'HIDRAULIC',
  FINISHING = 'FINISHING',
  FURNITURE = 'FURNITURE',
  EQUIPMENT = 'EQUIPMENT',
  INTERNET = 'INTERNET',
  COOLING = 'COOLING',
  OTHERS = 'OTHERS',
}

export enum MaintenanceTypeTranslated {
  Elétrica = 'Elétrica',
  Mecânica = 'Mecânica',
  Hidraulica = 'Hidraulica',
  Acabamento = 'Acabamento',
}

export enum MaintenanceStatus {
  REQUESTED = 'REQUESTED',
  RECEIVED = 'RECEIVED',
  PROGRESS = 'PROGRESS',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  REFUSED = 'REFUSED',
  APPROVED = 'APPROVED',
}

export enum MaintenanceSort {
  NEWER = 'NEWER',
  OLDER = 'OLDER',
  STATUS = 'STATUS',
  PLACE = 'PLACE',
  STATUSINVERSE = 'STATUSINVERSE',
  PLACEINVERSE = 'PLACEINVERSE',
}

export interface Area {
  id?: number;
  name?: string;
  unit?: Unit;
  cleanings?: Cleaning[];
}

export enum MaintenancePeriod {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
}

export function maintenanceTypeTranslator(type: MaintenanceType) {
  return {
    ELETRIC: 'Elétrica',
    MECHANIC: 'Mecânica',
    HIDRAULIC: 'Hidráulica',
    FINISHING: 'Acabamento',
    FURNITURE: 'Móveis',
    EQUIPMENT: 'Equipamentos',
    INTERNET: 'Internet',
    COOLING: 'Ar-Condicionado',
    OTHERS: 'Outros',
  }[type];
}

export function maintenancePeriodTranslator(maintenancePeriod: MaintenancePeriod) {
  switch(maintenancePeriod) {
    case MaintenancePeriod.MORNING:
      return 'Manhã';
    case MaintenancePeriod.AFTERNOON:
      return 'Tarde';
    default:
      return '-';
  }
}

export function handleErrors(error) {
  switch(error) {
    case "ROOM_REQUIRED_FOR_APARTMENT_TYPE":
      return "É necessário informar o quarto desejado para esse tipo de manutenção!";
    case "AREA_REQUIRED_FOR_COMMON_TYPE":
      return "É necessário informar a área desejada para esse tipo de manutenção!";
    case "TYPE_NOT_FOUND":
      return "Tipo de manutenção não encontrado, tente novamente!";
    case "USER_NOT_FOUND":
      return "Não foi possível encontrar o usuário, recarregue a página e tente novamente!"
    case "UNIT_NOT_FOUND":
      return "Não foi possível encontrar a unidade, recarregue a página e tente novamente!"
    case "MAINTENANCE_NOT_FOUND":
      return "Não foi possível encontrar a manutenção desejada, tente novamente!"
    case "EMPLOYEE_NOT_INFORMED":
      return "É necessário informar o funcionário para a manutenção."
    case "EMPLOYEE_NOT_FOUND":
      return "Não foi possível encontrar o funcionário, tente novamente!"
    case "PROGRESS_NOT_REASSIGNABLE":
      return "O serviço já foi iniciado pelo funcionário, não será possível reatribuir!"
    case "MAINTENANCE_ALREADY_FINISHED":
      return "O serviço já foi finalizado."
    case "MAINTENANCE_ALREADY_CANCELED":
      return "O serviço já foi cancelado."
    case "MAINTENANCE_NOT_OWNED":
      return "Somente o próprio usuário que abriu a manutenção deverá fechar ou cancelar."
    default:
      return "Ocorreu um problema ao executar essa ação. Por favor, tente novamente ou contate a equipe de desenvolvimento."
  }
}

export class Maintenance extends RestEntity<Maintenance> {
  id?: number;
  roomNumber?: string;
  areaType?: MaintenanceArea;
  area?: Area;
  type?: MaintenanceType;
  status?: MaintenanceStatus;
  photoUrl?: string;
  comments?: string;
  user?: User;
  employee?: Employee;
  subcategory?: IssueCategory;
  adminComment?: string;
  employeeComment?: string;
  replyComment?: string;
  guestCommentReply?: string;
  commentRejoinder?: string;
  replyPhotoUrl?: string;
  serviceNumber?: string;
  unit?: Unit;
  finishedDate?: Date;
  approvedDate?: Date;
  startedDate?: Date;
  room?: Room;
  suggestedServicePeriod?: MaintenancePeriod;
  unattendedAccessAllowed?: boolean;

  constructor(obj?: DeepPartial<Maintenance>) {
    super(obj);

    if (obj && obj.user) {
      this.user = new User(obj.user);
    }

    if (obj && obj.employee) {
      this.employee = new Employee(obj.employee);
    }

    if (obj && obj.subcategory) {
      this.subcategory = new IssueCategory(obj.subcategory);
    }

    if (obj && obj.unit) {
      this.unit = new Unit(obj.unit);
    }
  }

  get statusBadge(): { text: string; class: string } {
    if (this.status === MaintenanceStatus.REQUESTED) {
      return { text: 'Aberta', class: 'badge-warning' };
    } else if (this.status === MaintenanceStatus.RECEIVED) {
      return { text: 'Recebida', class: 'badge-warning' };
    } else if (this.status === MaintenanceStatus.PROGRESS) {
      return { text: 'Em andamento', class: 'badge-primary' };
    } else if (this.status === MaintenanceStatus.FINISHED) {
      return { text: 'Finalizada', class: 'badge-info' };
    } else if (this.status === MaintenanceStatus.CANCELED) {
      return { text: 'Cancelada', class: 'badge-light' };
    } else if (this.status === MaintenanceStatus.APPROVED) {
      return { text: 'Aprovada', class: 'badge-success' };
    } else if (this.status === MaintenanceStatus.REFUSED) {
      return { text: 'Recusada', class: 'badge-danger' };
    } else {
      return { text: '?', class: 'badge-dark' };
    }
  }

  get typeTranslation(): string {
    return maintenanceTypeTranslator(this.type);
  }

  get sla() {
    switch (this.status) {
      case MaintenanceStatus.RECEIVED:
      case MaintenanceStatus.REQUESTED:
      case MaintenanceStatus.REFUSED:
      case MaintenanceStatus.PROGRESS:
        return distanceInWordsStrict(new Date(), new Date(this.createdDate), { locale: localeBR });

      case MaintenanceStatus.FINISHED:
      case MaintenanceStatus.APPROVED:
        if (this.finishedDate) {
          return distanceInWordsStrict(new Date(this.finishedDate), new Date(this.createdDate), { locale: localeBR });
        } else if (this.updatedDate) {
          return distanceInWordsStrict(new Date(this.updatedDate), new Date(this.createdDate), { locale: localeBR });
        }
        return '-';
      case MaintenanceStatus.CANCELED:
        return '-';

      default:
        return '-';
    }
  }

  get slaHours() {
    switch (this.status) {
      case MaintenanceStatus.RECEIVED:
      case MaintenanceStatus.REQUESTED:
      case MaintenanceStatus.REFUSED:
      case MaintenanceStatus.PROGRESS:
        return differenceInHours(new Date(), new Date(this.createdDate));

      case MaintenanceStatus.FINISHED:
      case MaintenanceStatus.APPROVED:
        if (this.finishedDate) {
          return differenceInHours(new Date(this.finishedDate), new Date(this.createdDate));
        } else if (this.updatedDate) {
          return differenceInHours(new Date(this.updatedDate), new Date(this.createdDate));
        }
        return undefined;
      case MaintenanceStatus.CANCELED:
        return undefined;

      default:
        return undefined;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private API: APIService) {}

  async loadHotelMaintenances(hotelCode: number, page: number = 1, limit: number = 25, filter: any = {}, hideLoading = false) {
    return await this.API.get<APIPaginationdResponse<Maintenance>>(
      `/maintenances?hotelCode=${hotelCode}&page=${page}&limit=${limit}`,
      { ...filter },
      { tokenRequired: true, hideLoading },
    );
  }

  async read(id: number) {
    return await this.API.get<Maintenance>(`/maintenances/${id}`, {}, { tokenRequired: true });
  }

  create(maintenance: Maintenance, file?: any, hideLoading = false) {

    const formData = new FormData();

    formData.append('areaType', maintenance.areaType);
    formData.append('comments', maintenance.comments);
    formData.append('type', maintenance.type);
    formData.append('unit', String(maintenance.unit.hotelCode));

    if (maintenance.area) {
      formData.append('area', String(maintenance.area.id));
    }

    if (maintenance.employee) {
      formData.append('employee', String(maintenance.employee.id));
    }

    if (maintenance.roomNumber) {
      formData.append('roomNumber', String(maintenance.roomNumber));
    }

    if (maintenance.room) {
      formData.append('room', String(maintenance.room.id));
    }

    if (file) {
      formData.append('file', file);
    }

    if (maintenance.subcategory) {
      formData.append('subcategory', maintenance.subcategory.id as any);
    }

    if (maintenance.createdDate) {
      formData.append('createdDate', maintenance.createdDate as any);
    }

    if (maintenance.finishedDate) {
      formData.append('finishedDate', maintenance.finishedDate as any);
    }

    if (maintenance.approvedDate) {
      formData.append('approvedDate', maintenance.approvedDate as any);
    }

    if (maintenance.status) {
      formData.append('status', maintenance.status as any);
    }

    return this.API.post<Maintenance | FormData, Maintenance>('/maintenances', formData, { tokenRequired: true, hideLoading });
  }

  async update(id: number, maintenance: Maintenance, hideLoading = false) {
    return await this.API.patch<Maintenance>(`/maintenances/` + id, maintenance, { tokenRequired: true, hideLoading });
  }

  delete(id: number) {
    return this.API.delete<Maintenance>(`/maintenances/${id}`, {}, { tokenRequired: true });
  }

  async assignEmployee(serviceId: number, id: number) {
    return await this.API.patch(`/maintenances/${serviceId}/assign`, { employeeId: id }, { tokenRequired: true });
  }

  async updateAdmin(serviceId: number, bodyData?: { replyComment?: string; status?: MaintenanceStatus; commentRejoinder?: string }) {
    return await this.API.patch(`/maintenances/${serviceId}/update`, bodyData, { tokenRequired: true });
  }

  async uploadPhoto(serviceId: number, file) {
    const formData = new FormData();

    formData.append('file', file);

    return await this.API.patch(`/maintenances/${serviceId}/update`, formData, { tokenRequired: true });
  }
}
