import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Unit } from '../../entities/units/unit.entity';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Space } from 'src/app/entities/spaces/space.entity';
import { SpacesService } from '../spaces.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Schedule } from '../../entities/spaces/schedule.entity';
import { UnitsService } from '../units.service';
import { ResidentsService } from '../../residents/residents.service';
import { Guest } from '../../entities/residents/guest.entity';
import { AuthService } from 'src/app/auth.service';
import { SchedulesService } from '../schedules.service';
import Swal from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {addHours, getDate, getMonth, setDate} from 'date-fns';
import { User } from 'src/app/entities/residents/user.entity';
import { Employee } from 'src/app/entities/employees/employee.entity';
import { EmployeesService } from 'src/app/employees/employees.service';
import { ErrorsEnum, ErrorsTranslated } from 'src/app/shared/errorMessagesTranslation';
import { dateRangeValidator } from 'src/app/shared/validators/date.validator';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss']
})
export class ScheduleFormComponent implements OnInit, OnChanges {
  form: FormGroup;
  spaces: Space[] = [];
  residents: Guest[] = [];
  employees: Employee[] = []
  schedule: Schedule = new Schedule({attendances: []});
  attendees: User[];
  adminId: number = null;
  loading = false;
  errorMessage: string;
  hotelInfo: Unit;
  chosenSpace: Space;
  dayISO: Date;
  @Input() id: number = undefined;
  @Input() isModal = false;
  constructor(
    private formBuilder: FormBuilder,
    public spacesService: SpacesService,
    public schedulesService: SchedulesService,
    public unitsService: UnitsService,
    public employeeSrvc: EmployeesService,
    public residentsService: ResidentsService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public navService: NavService,
    public modal: NgbActiveModal) {
  }

  closeModal() {
    this.modal.close({updated: true});
  }

  async ngOnInit() {
    this.hotelInfo = this.navService.hotelInfo;

    this.route.params.subscribe((params) => {

      if (params.scheduleId) {
        this.id = params.scheduleId;
        this.initCreateForm();
        this.initUpdateForm();
      } else {
        this.initCreateForm();
      }
    });

    if (this.id) {
      this.initCreateForm();
      this.initUpdateForm();
    }

    this.authService.getLoggedProfile().subscribe(user => {
      this.adminId = user.id;
    });
    this.spaces = (await this.unitsService.read(this.hotelInfo.hotelCode)).spaces;
    this.residents = (await this.residentsService.getAllGuests(this.hotelInfo.hotelCode)).data.filter(r => r.user ? true : false);
    this.employees = await this.employeeSrvc.getAllFromUnit(this.hotelInfo.hotelCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.id.currentValue !== changes.id.previousValue) {
      this.initUpdateForm();
    }
  }

  onSpaceChange(event, value: number) {
    // values is the space id
    const chosenSpace = this.spaces.find(space => space.id === Number(value));
    this.chosenSpace = chosenSpace
  }

  initCreateForm() {
    this.form = this.formBuilder.group({
      space: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      user: [null],
    }, {
      validators: [
        dateRangeValidator("startDate", "endDate"),
      ]
    });
  }

  initUpdateForm() {
    this.loading = true;
    this.schedulesService.read(this.id).then(schedule => {
      this.chosenSpace = schedule.space
      this.loading = false;

      this.errorMessage = null;
      this.schedule = new Schedule(schedule);
      this.schedule.startDate = addHours(this.schedule.startDate, -3);

      this.schedule.endDate = addHours(schedule.space.fullDay ? this.schedule.startDate : this.schedule.endDate, -3);

      this.form.patchValue({
        ['space']: this.schedule.space.id,
        ['startDate']: String(this.schedule.startDate.toISOString()).slice(0, schedule.space.fullDay ? 10 :  16),
        ['endDate']: String(schedule.space.fullDay ? this.schedule.startDate.toISOString() : this.schedule.endDate.toISOString()).slice(0, 16),
        ['user']: this.schedule.user.id,
      });
    }, _err => {
      this.errorMessage = 'Ocorreu um erro. Tente novamente, por favor.';
    });
  }

  submit() {
    if (this.id) {
      this.update();
    } else {
      this.create();
    }
  }

  getSelectedDate(event, value) {
    const valueStringArray = value.split('-')
    const dateValue: Date = new Date(valueStringArray[0], (Number(valueStringArray[1]) - 1), valueStringArray[2]);
    //
    this.dayISO = dateValue;

    // this.checkIfScheduleIsFree();

    // this.dayFormated = `${getDate(dateValue)}/${getMonth(dateValue) + 1}`;

    // this.dateSelected = true;
  }

  create() {
    this.loading = true;
    const startDate = new Date(this.form.value.startDate);
    const endDate = new Date(this.form.value.endDate);
    const startDateUTC =  Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
                          startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds());
    const endDateUTC =  Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(),
                          endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds());

    let initialHourISO: Date;
    let endHourISO: Date;

    if (this.chosenSpace && this.chosenSpace.fullDay) {
      const chosenDateStringArray = this.form.value.startDate.split('-')
      const chosenDate = new Date(chosenDateStringArray[0], (Number(chosenDateStringArray[1]) - 1), chosenDateStringArray[2])
      const initialTime = this.formatIncomingDate(this.chosenSpace.startHour, chosenDate);

      const finishTime = this.formatIncomingDate(this.chosenSpace.endHour, chosenDate);

      initialHourISO = setDate(initialTime, getDate(this.dayISO));

      endHourISO = setDate(finishTime, getDate(this.dayISO));
    }

    this.schedule = new Schedule({
      space: {id: this.form.value.space},
      startDate: initialHourISO ? initialHourISO : new Date(startDateUTC).toISOString(),
      endDate: endHourISO ? endHourISO : new Date(endDateUTC).toISOString(),
      user: {id: this.form.value.user},
    });
    this.schedulesService.create(this.schedule).then(schedule => {
      this.successNew(schedule);
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in create schedule component', err);
      this.loading = false;
      if (err.error === ErrorsEnum.HAS_UNFINISHED_SCHEDULE){
        Swal.fire({
          type: 'error',
          title: 'Ação inválida!',
          text: 'Não é possível reservar esse espaço pois você tem uma reserva não concluída.',
        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'Erro!',
          text: ErrorsTranslated[err.error] ? ErrorsTranslated[err.error] : "Verifique os campos novamente.",
        });
      }
    });
  }

  update() {
    this.loading = true;
    // TODO SEPARAR RESPONSABILIDADES DO MÉTODO
    const startDateStringArray = this.form.value.startDate.split('-')
    const endDateStringArray = this.form.value.endDate.split('-')
    const startDate = new Date(startDateStringArray[0], startDateStringArray[1], startDateStringArray[2]);
    const endDate = new Date(endDateStringArray[0], endDateStringArray[1], endDateStringArray[2]);
    const startDateUTC =  Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(),
      startDate.getUTCHours(), startDate.getUTCMinutes(), startDate.getUTCSeconds());
    const endDateUTC =  Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(),
      endDate.getUTCHours(), endDate.getUTCMinutes(), endDate.getUTCSeconds());

    let initialHourISO: Date;
    let endHourISO: Date;

    if (this.chosenSpace && this.chosenSpace.fullDay) {
      const chosenDateStringArray = this.form.value.startDate.split('-')
      const chosenDate = new Date(chosenDateStringArray[0], (Number(chosenDateStringArray[1]) - 1), chosenDateStringArray[2])
      const initialTime = this.formatIncomingDate(this.chosenSpace.startHour, chosenDate);

      const finishTime = this.formatIncomingDate(this.chosenSpace.endHour, chosenDate);

      initialHourISO = setDate(initialTime, getDate(this.dayISO));

      endHourISO = setDate(finishTime, getDate(this.dayISO));
    }

    const schedule = new Schedule({
      id: this.id,
      space: {id: this.form.value.space},
      startDate: initialHourISO ? initialHourISO : this.form.value.startDate,
      endDate: endHourISO ? endHourISO : this.form.value.endDate,
      user: {id: this.form.value.user},
    });
    this.schedulesService.update(schedule, this.id).then(() => {
      this.successEdit();
      this.loading = false;
      this.errorMessage = null;
    }, err => {
      console.error('Error in update schedule component', err);
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Erro!',
        text: ErrorsTranslated[err.error] ? ErrorsTranslated[err.error] : "Verifique os campos novamente.",
      });
    });
  }

  showError(field?: string) {
    return (this.form.get(field).invalid && this.form.get(field).touched) ||
        (!this.form.get(field).touched && this.errorMessage && this.errorMessage.length > 0 && this.form.get(field).invalid );
  }

  showDateRangeError(field?: 'startDate' | 'endDate') {
    if (!this.form.get(field)) {
      return false;
    }

    if(this.form.get(field).errors && !this.form.get(field).errors.required) {
      return true;
    };
    };


  successNew(schedule: Schedule) {
    Swal.fire({
      title: 'Reserva criada!',
      type: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary float-left',
      cancelButtonText: 'Cadastrar outra',
      showCloseButton: true,
      onClose: () => this.goToList(),
    }).then(resp => {
      if (this.isModal) {
        this.closeModal();
      } else {
        if (resp.value) {
          this.goToDetails(schedule.id);
        } else {
          this.goToForm();
        }
      }
    });
  }

  successEdit() {
    return Swal.fire({
      title: 'Reserva editada!',
      type: 'success',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonClass: 'bg-secondary',
      cancelButtonText: 'Voltar',
      showCloseButton: true,
      onClose: () => {},
    });
  }

  clickDelete(id: number) {
    Swal.fire({
      title: 'Deseja mesmo excluir esta reserva?',
      type: 'warning',
      showConfirmButton: true,
      confirmButtonText: 'Sim, excluir',
      confirmButtonClass: 'btn btn-danger',
      showCancelButton: true,
      cancelButtonClass: 'btn btn-secondary',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
    }).then(resp => {
     if (resp.value) {
        this.delete(id);
     }
    });
  }

  delete(id: number) {
    this.schedulesService.delete(id).then(del => {
      Swal.fire({
        type: 'success',
        text: 'Reserva ' + id + ' deletada com sucesso.',
        titleText: 'Excluído.'
      }).then(resp => {
        if (this.isModal) {
          this.closeModal();
        }
      });
    }).catch(err => {
      Swal.fire({
        type: 'error',
        titleText: 'Erro ao excluir.',
        text: 'Por favor, tente novamente.',
      });
    });
  }



  goToList() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'schedule']);
  }
  goToDetails(id: number) {
    this.router.navigate(['/unit', this.hotelInfo.code, 'schedule']);
  }

  goToForm() {
    this.router.navigate(['/unit', this.hotelInfo.code, 'schedule', 'create']);
  }

  /** helper function */
  formatIncomingDate(date: string, chosenDate: Date) {
    // const rawDate = new Date();

    const splitDate = String(chosenDate).split(' ');

    splitDate[4] = date;

    const joinedDate = splitDate.join(' ');

    const newDate = new Date(joinedDate);

    return newDate;
  }

}
