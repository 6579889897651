import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { User } from 'src/app/entities/residents/user.entity';
import { AuthService } from '../../auth.service';
import Swal from 'sweetalert2';
import { ErrorsEnum, ErrorsTranslated } from 'src/app/shared/errorMessagesTranslation';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public user: User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router) {

    this.loginForm = this.fb.group({
      email: ['@uliving.com.br', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {
  }

  // Simple Login
  login() {
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
      .then((res) => {
        this.router.navigate(['/units']);
      },
        (err) => {
          console.error(err);
            if (err.error === ErrorsEnum.ERR_AUTH_LOGIN_WRONG_CREDENTIALS) {
              Swal.fire({
                type: 'error',
                text: ErrorsTranslated[ErrorsEnum.ERR_AUTH_LOGIN_WRONG_CREDENTIALS],
                title: 'Ops',
                showConfirmButton: true,
                onClose: () => this.router.navigate(['/login']),
              });
            } else {
              Swal.fire({
                type: 'error',
                text: 'Ocorreu um erro...',
                title: 'Ops',
                showConfirmButton: true,
              });
            }
        },
        );
  }

}
