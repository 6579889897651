import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';

// Components
import { ContentComponent } from './layouts/content/content.component';
// Routes
import { content } from './shared/routes/content.routes';
import { LoginComponent } from './auth/login/login.component';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { Error404Component } from './pages/error-pages/error404/error404.component';
import { ListUnitsComponent } from './unit/list-units/list-units.component';
import { UnitsFormComponent } from './unit/units-form/units-form.component';
import { ChatAppComponent } from './apps/chat/chat-app/chat-app.component';
import { VisitorFormComponent } from './visitor/visitor-form/visitor-form.component';
import { RoleRedirect } from './shared/guard/RoleRedirect.guard';
import { RoleGuard } from './shared/guard/RoleGuard.guard';
import { ListIssueCategoriesComponent } from './issue-categories/list-issue-category/list-issue-category.component';
import { IssueCategoryFormComponent } from './issue-categories/issue-category-form/issue-category-form.component';
import { ListOccurrenceCategoriesComponent } from './occurrence-categories/list-occurrence-category/list-occurrence-category.component';
import { OccurrenceCategoryFormComponent } from './occurrence-categories/occurrence-category-form/occurrence-category-form.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UpdatePasswordComponent } from './auth/update-password/update-password.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'units',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent,
  },
  {
    path: 'visitor',
    children: [
      {
        path: 'pending',
        component: VisitorFormComponent,
      },
    ],
  },
  {
    path: 'unit/:hotelCode',
    component: ContentComponent,
    children: content,
    canActivate: [
      // AdminGuard,
      SecureInnerPagesGuard,
    ],
  },
  {
    path: 'config',
    component: ContentComponent,
    children: [
      {
        path: 'maintenance/subcategories',
        component: ListIssueCategoriesComponent,
        data: { path: 'config.maintenance', action: 'list' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'maintenance/subcategories/create',
        component: IssueCategoryFormComponent,
        data: { path: 'config.maintenance', action: 'create' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'maintenance/subcategories/:issueCategoryId/edit',
        component: IssueCategoryFormComponent,
        data: { path: 'config.maintenance', action: 'update' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'occurrence/subcategories',
        component: ListOccurrenceCategoriesComponent,
        data: { path: 'config.occurrence', action: 'list' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'occurrence/subcategories/create',
        component: OccurrenceCategoryFormComponent,
        data: { path: 'config.occurrence', action: 'create' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'occurrence/subcategories/:issueCategoryId/edit',
        component: OccurrenceCategoryFormComponent,
        data: { path: 'config.occurrence', action: 'update' },
        canActivate: [RoleRedirect]
      },
    ]
  },
  {
    path: 'units',
    component: ContentComponent,
    data: { path: 'units' },
    children: [
      {
        path: '',
        component: ListUnitsComponent,
        data: { path: 'units', action: 'list' },
        canActivate: [RoleRedirect]
      },
      {
        path: 'chat',
        component: ChatAppComponent,
      },
      {
        path: 'chat/:id',
        component: ChatAppComponent,
      },
      {
        path: 'create',
        component: UnitsFormComponent,
        data: { path: 'units', action: 'create' },
      },
      {
        path: ':hotelCode/edit',
        component: UnitsFormComponent,
        data: { path: 'units', action: 'update' },
      },
    ],
    canActivate: [
      // AdminGuard,
      SecureInnerPagesGuard,
    ],
    canActivateChild: [RoleGuard],
  },
  // {
  //   path: 'compact-sidebar',
  //   component: CompactSidebarComponent,
  //   children: compactSidebar,
  // },
  // {
  //   path: 'compact-sidebar-icons',
  //   component: CompactSidebarIconsComponent,
  //   children: compactSidebarIcons
  // },
  // {
  //   path: '',
  //   component: FullWidthComponent,
  //   children: full
  // },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: NoPreloading,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
