import { Injectable } from '@angular/core';
import { APIService, APIPaginationdResponse, APIRequestOptions } from '../api.service';
import { Guest, GuestQuantitativeReport } from '../entities/residents/guest.entity';
import { UserStatus, User } from '../entities/residents/user.entity';
import { Statement } from '../entities/statement/statement.entity';

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  constructor(public API: APIService) {}

  async list(hotelCode: number, page: number = 1, limit: number = 25, period?: Date | string, searchParam?: string): Promise<APIPaginationdResponse<Statement>> {
    const params = {
      limit,
      page,
      period: period ? `${period}-01` : '',
      searchParam: searchParam ? searchParam : '',
    };

    const response = await this.API.get<APIPaginationdResponse<Statement>>(`/statement/${hotelCode}/list/`, params, { tokenRequired: true });
    const statements = response.data.map((s) => new Statement(s));
    return { ...response, data: statements };
  }

  create(statement: Statement) {
    return this.API.post<Statement, Statement>('/statement/save', statement, { tokenRequired: true });
  }

  read(statementCode: number) {
    return this.API.get<Statement>(`/statement/read/${statementCode}`, {}, { tokenRequired: true });
  }

  deleteItem(statementId: number) {
    return this.API.post<{ statementId: number }>('/statement/item/delete', { statementId }, { tokenRequired: true });
  }

  deleteStatement(statementId: number) {
    return this.API.post<{ statementId: number }>('/statement/delete', { statementId }, { tokenRequired: true });
  }
}
