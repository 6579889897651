import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/entities/units/unit.entity';
import { ActivatedRoute } from '@angular/router';
import { NavService } from 'src/app/shared/service/nav.service';
import { Maintenance, MaintenanceService, MaintenanceSort, MaintenanceStatus, MaintenanceType, maintenanceTypeTranslator } from '../maintenance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-maintenances',
  templateUrl: './list-maintenances.component.html',
  styleUrls: ['./list-maintenances.component.scss'],
})
export class ListMaintenancesComponent implements OnInit {
  maintenances: Maintenance[] = [];
  types = Object.keys(MaintenanceType).map(k => ({ name: maintenanceTypeTranslator(k as MaintenanceType), value: k }));

  loading = true;
  hotelInfo: Unit;

  pagination = {
    page: 1,
    limit: 15,
    pages: 5,
    collectionSize: 75,
  };

  searchTerm = '';

  chosenStatus: MaintenanceStatus = 'ALL' as any;
  chosenType: MaintenanceType = 'ALL' as any;
  chosenSortingOrder: MaintenanceSort = MaintenanceSort.NEWER;
  searchQuery: string = null;
  minDate;
  maxDate;

  maintenanceTypeTranslator = maintenanceTypeTranslator;
  MaintenanceStatus = MaintenanceStatus;
  maintenanceSortOptions = MaintenanceSort;

  constructor(private maitenanceSrvc: MaintenanceService, public route: ActivatedRoute, public navService: NavService, public modalService: NgbModal) {}

  ngOnInit() {
    this.navService.currentUnit$.subscribe(unit => {
      this.hotelInfo = unit;

      this.loadMaintenanceRequests();
    });
  }

  async loadMaintenanceRequests() {
    const params: any = {};
    if(!this.hotelInfo) return;

    if (this.chosenStatus) {
      params.status = this.chosenStatus;
      if (this.chosenStatus === ('ALL' as any)) {
        delete params.status;
      }
    }
    if (this.chosenType) {
      params.type = this.chosenType;
      if (this.chosenType === ('ALL' as any)) {
        delete params.type;
      }
    }
    if (this.chosenSortingOrder) {
      params.sortBy = this.chosenSortingOrder;
    }

    if (this.searchQuery) {
      params.searchQuery = this.searchQuery;
    }

    if (this.minDate && this.maxDate) {
      params.endDate = this.maxDate;
      params.startDate = this.minDate;
    }

    const requests = await this.maitenanceSrvc.loadHotelMaintenances(this.hotelInfo.hotelCode, this.pagination.page, this.pagination.limit, params);
    this.maintenances = [];
    requests.data.forEach(request => {
      const maintenance = new Maintenance(request);

      this.maintenances.push(maintenance);
    });

    this.pagination.pages = requests.totalPages;
    this.pagination.collectionSize = requests.totalPages * this.pagination.limit;
    this.loading = false;
  }

  openFilterModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {
    });
  }

  clean() {
    this.chosenStatus = 'ALL' as any;
    this.chosenType = 'ALL' as any;
    this.chosenSortingOrder = MaintenanceSort.NEWER;
    this.searchQuery = null;
    this.minDate = null;
    this.maxDate = null;

    this.loadMaintenanceRequests();
  }
}
