import { Injectable } from '@angular/core';
import { APIService } from '../api.service';
import { CleaningType } from '../entities/cleaning/cleaning-type.entity';
import { Cleaning, CleaningPeriod, CleaningStatus } from '../entities/cleaning/cleaning.entity';
import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import { ExtraService } from '../entities/cleaning/extra-service.entity';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { User } from '../entities/residents/user.entity';

export interface CleaningQR {
  hotelCode: number;
  roomId?: number;
  areaId?: number;
  type: 'area' | 'room';
}

export interface CleaningReport {
  roomNumber: String;
  date: Date;
  period?: CleaningPeriod;
  status: String;
  totalPrice: number;
  user: User;
  cleaningType: CleaningType;
}

@Injectable({
  providedIn: 'root',
})
export class CleaningService {
  constructor(public API: APIService) {}

  async getAllFromUnit(hotelCode: number, page = 1) {
    const cleanings = await this.API.get<Cleaning[]>(`/cleaning/all/${hotelCode}`, { page }, { tokenRequired: true });
    return cleanings.map(s => new Cleaning(s)); // new
  }

  getAllCleanings(hotelCode: number, date?: Date, report: boolean = false) {
    const qb = RequestQueryBuilder.create();

    const filter = [];

    if (report) {
      filter.push(
        { field: 'date', operator: 'between', value: `${startOfMonth(date).toISOString()},${endOfMonth(date).toISOString()}` },
      );
    } else {
      filter.push(
        { field: 'date', operator: 'between', value: `${addDays(new Date(date), -35).toISOString()},${addDays(new Date(date), 35).toISOString()}` }
      );
    }

    filter.push(
      { field: 'unit.HotelCode', operator: 'eq', value: hotelCode },
      { field: 'status', operator: 'ne', value: CleaningStatus.CANCELLED }
    );

    const queryString = qb
      .setJoin([{ field: 'area' }, { field: 'room' }, { field: 'user' }, { field: 'cleaningType' }])
      .setFilter(filter)
      .query();

    return this.API.get<Cleaning[]>(`/cleaning?${queryString}`, {}, { tokenRequired: true });
  }

  async getCleaningReport(hotelCode: number, date?: Date) {
    return this.API.post<any, CleaningReport[]>('/cleaning/report', { date, hotelCode }, { tokenRequired: true });
  }

  create(cleaning: Cleaning, weeksToRepeat?:number) {
    return this.API.post<any, Cleaning>(`/cleaning?weeksToRepeat=${weeksToRepeat}`, cleaning, { tokenRequired: true });
  }

  updateAdmin(cleaning: Cleaning, id: number) {
    return this.API.patch<Cleaning, Cleaning>(`/cleaning/updateAdmin/${cleaning.id}`, cleaning, { tokenRequired: true });
  }

  read(cleaningId: number) {
    return this.API.get<Cleaning>(`/cleaning/${cleaningId}`, {}, { tokenRequired: true }); //new
  }
  assign(cleaning: any) {
    return this.API.patch<any, Cleaning>(`/cleaning/assign`, cleaning, { tokenRequired: true });
  }

  deleteCleaning(cleaningId: number) {
    return this.API.delete<Cleaning>(`/cleaning/${cleaningId}`, {}, { tokenRequired: true }); //new
  }

  createType(formData: CleaningType, file?: any) {
    let cleaningData: CleaningType | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', formData.name);
      form.append('price', String(formData.price));
      form.append('description', formData.description);
      form.append('duration', String(formData.duration));
      form.append('frequency', formData.frequency);
      form.append('unit', String(formData.unit.hotelCode));
      form.append('showToGuests', String(formData.showToGuests));

      if (formData.packQuantity) {
        form.append('packQuantity', String(formData.packQuantity));
      }

      cleaningData = form;
    } else {
      cleaningData = formData;
    }

    return this.API.post<CleaningType | FormData, CleaningType>('/cleaning-type', cleaningData, { tokenRequired: true });
  }

  readType(id: number) {
    return this.API.get<CleaningType>(`/cleaning-type/${id}`, {}, { tokenRequired: true });
  }

  updateType(formData: CleaningType, id: number, file?: any) {
    let cleaningData: CleaningType | FormData;

    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', formData.name);
      form.append('price', String(formData.price));
      form.append('description', formData.description);
      form.append('duration', String(formData.duration));
      form.append('frequency', formData.frequency);
      form.append('unit', String(formData.unit.hotelCode));
      form.append('showToGuests', String(formData.showToGuests));

      if (formData.packQuantity) {
        form.append('packQuantity', String(formData.packQuantity));
      }

      cleaningData = form;
    } else {
      cleaningData = formData;
    }

    return this.API.patch<CleaningType | FormData, CleaningType>(`/cleaning-type/${id}`, cleaningData, { tokenRequired: true });
  }

  deleteType(id: number) {
    return this.API.delete<CleaningType>(`/cleaning-type/${id}`, {}, { tokenRequired: true });
  }

  generateQR(data: CleaningQR): Promise<{ QR: string }> {
    return this.API.post('/cleaning/QR/generate', data, { tokenRequired: true });
  }

  getAllCleaningTypes(hotelCode: number) {
    return this.API.get<CleaningType[]>(
      '/cleaning-type',
      {
        hotelCode,
      },
      { tokenRequired: true },
    );
  }

  getAllExtraServices(hotelCode: number) {
    return this.API.get<ExtraService[]>(
      '/extra-services',
      {
        hotelCode,
      },
      { tokenRequired: true },
    );
  }

  createExtraService(formData: ExtraService, file?: any) {
    let extraServiceData: ExtraService | FormData;

    if (file) {
      const form = new FormData();
      form.append('name', formData.name);
      form.append('price', String(formData.price));
      form.append('description', formData.description);
      form.append('unit', String(formData.unit.hotelCode));
      form.append('file', file);
      extraServiceData = form;
    } else {
      extraServiceData = formData;
    }
    return this.API.post<ExtraService | FormData, ExtraService>('/extra-services', extraServiceData, { tokenRequired: true });
  }

  readExtraService(id: number) {
    return this.API.get<ExtraService>(`/extra-services/${id}`, {}, { tokenRequired: true });
  }

  updateExtraService(formData: ExtraService, id: number, file?: any) {
    let extraServiceData: ExtraService | FormData;

    if (file) {
      const form = new FormData();
      form.append('name', formData.name);
      form.append('price', String(formData.price));
      form.append('description', formData.description);
      form.append('unit', String(formData.unit.hotelCode));
      form.append('file', file);
      extraServiceData = form;
    } else {
      extraServiceData = formData;
    }

    return this.API.patch<ExtraService | FormData, ExtraService>(`/extra-services/${id}`, extraServiceData, { tokenRequired: true });
  }

  delete(id: number) {
    return this.API.delete<ExtraService>(`/extra-services/${id}`, {}, { tokenRequired: true });
  }
}
